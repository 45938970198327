import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { httpsCallable } from "firebase/functions";
import { auth } from "../config/firebase"; // Ajuste conforme necessário
import { functions } from "../config/firebase"; // Ajuste conforme necessário
import "./setupClaims.css";

const SetupClaims = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuthorization = async () => {
      const currentUser = auth.currentUser;

      // Verifica se há um usuário logado e se o email é permitido
      if (!currentUser || currentUser.email !== "felipe.camarinha@gmail.com") {
        navigate("/naoautorizado");
      }
    };

    checkAuthorization();
  }, [navigate]);

  const handleSetupClaims = async () => {
    try {
      const setupClaimsFn = httpsCallable(functions, "setupClaims");
      const result = await setupClaimsFn();
      alert(result.data.message); // Exibe mensagem de sucesso
      console.log(result.data.message);
    } catch (error) {
      console.error("Erro ao configurar claims:", error);
      alert(
        "Erro ao configurar claims. Verifique o console para mais detalhes."
      );
    }
  };

  return (
    <section id="setup-claims">
      <div className="setup-claims">
        <h1>Atualizar Claims</h1>
        <button onClick={handleSetupClaims}>Run</button>
      </div>
    </section>
  );
};

export default SetupClaims;
