import React, { useState } from "react";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../config/firebase";
import "./Orcamentos.css";

const Orcamentos = () => {
  const [orcamentos, setOrcamentos] = useState([]); // Inicialmente vazio
  const [dataFiltro, setDataFiltro] = useState(""); // Filtro por data
  const [responsavelFiltro, setResponsavelFiltro] = useState(""); // Filtro por responsável

  // Função para buscar orçamentos com base nos filtros
  const buscarOrcamentos = async () => {
    try {
      const orcamentoRef = collection(db, "orcamentos");
      let filtros = [];

      // Adiciona filtros se houverem
      if (dataFiltro) {
        const dataInicio = new Date(dataFiltro);
        const dataFim = new Date(dataFiltro);
        dataFim.setHours(23, 59, 59, 999);
        filtros.push(where("date", ">=", dataInicio.toISOString()));
        filtros.push(where("date", "<=", dataFim.toISOString()));
      }
      if (responsavelFiltro) {
        filtros.push(where("savedBy", "==", responsavelFiltro));
      }

      // Query com filtros (ou sem filtros para buscar todos)
      const queryOrcamentos =
        filtros.length > 0 ? query(orcamentoRef, ...filtros) : orcamentoRef;

      const querySnapshot = await getDocs(queryOrcamentos);
      const orcamentosData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setOrcamentos(orcamentosData);
    } catch (error) {
      console.error("Erro ao buscar orçamentos:", error);
    }
  };

  return (
    <div className="container">
      <h1>Lista de Orçamentos</h1>

      {/* Filtros */}
      <div className="filtros">
        <div className="filtro-item">
          <label>Data do Orçamento:</label>
          <input
            type="date"
            value={dataFiltro}
            onChange={(e) => setDataFiltro(e.target.value)}
          />
        </div>
        <div className="filtro-item">
          <label>Responsável:</label>
          <select
            value={responsavelFiltro}
            onChange={(e) => setResponsavelFiltro(e.target.value)}
          >
            <option value="">Todos</option>
            <option value="Rayssa">Rayssa</option>
            <option value="Thaís">Thaís</option>
            <option value="Enny">Enny</option>
            <option value="Isabella">Isabella</option>
            <option value="Dione">Dione</option>
          </select>
        </div>
        <button onClick={buscarOrcamentos}>Buscar</button>
      </div>

      {/* Renderização dos Orçamentos */}
      {orcamentos.length > 0 ? (
        <div className="orcamento-grid">
          {orcamentos.map((orcamento) => (
            <div key={orcamento.id} className="orcamento-card">
              <p>
                <strong>Nome Cliente:</strong> {orcamento.clientName}
              </p>
              <p>
                <strong>Telefone Cliente:</strong> {orcamento.clientPhone}
              </p>
              <p>
                <strong>Data do Orçamento:</strong>{" "}
                {new Date(orcamento.date).toLocaleDateString("pt-BR")}
              </p>
              <p>
                <strong>Responsável:</strong> {orcamento.savedBy}
              </p>

              <div>
                <p>
                  <strong>Procedimentos:</strong>
                </p>
                {orcamento.calculadoraInfo.linhas.map((linha, index) => (
                  <p key={index}>
                    ✨ {linha.tipo} - {linha.quantidade} Sessões de{" "}
                    {linha.procedimento}
                  </p>
                ))}
              </div>

              <div>
                <p>
                  <strong>Descontos Aplicados / Forma de Pagamento:</strong>{" "}
                </p>
                {orcamento.calculadoraInfo.descontos ? (
                  Object.entries(orcamento.calculadoraInfo.descontos).map(
                    ([tipo, valor], index) => {
                      const formaPagamento = buscarFormaPagamento(
                        tipo,
                        orcamento.calculadoraInfo.selectedOptions.text
                      );
                      return (
                        <p key={index}>
                          💵 <strong>{tipo}:</strong> {valor} - {formaPagamento}
                        </p>
                      );
                    }
                  )
                ) : (
                  <p>Nenhum desconto aplicado.</p>
                )}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <p>Nenhum orçamento encontrado.</p>
      )}
    </div>
  );
};

const buscarFormaPagamento = (tipo, text) => {
  const lowerTipo = tipo.toLowerCase(); // Normaliza o tipo
  const opcoes = text.split("ou").map((option) => option.trim()); // Divide as opções e remove espaços extras

  // Mapear tipos para possíveis nomes que aparecem no texto
  const tipoMap = {
    credito: "crédito", // Busca por "crédito" no texto
    boleto: "boleto", // Busca por "boleto" no texto
    pix: "pix", // Caso tenha PIX, podemos adicionar aqui
  };

  // Procura a opção correspondente ao tipo
  const formaPagamento = opcoes.find((option) =>
    option.toLowerCase().includes(tipoMap[lowerTipo])
  );

  return formaPagamento || "Forma de pagamento não encontrada"; // Retorna a opção encontrada ou mensagem padrão
};

export default Orcamentos;
