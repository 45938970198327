// RegistroDePontos.js
import React, { useState, useEffect } from "react";
import { db } from "../config/firebase";
import { collection, addDoc, query, where, getDocs } from "firebase/firestore";
import { useFirebaseAuth } from "./FirebaseAuthContext";
import "./RegistroDePontos.css";
import { EmailAuthProvider, reauthenticateWithCredential } from "firebase/auth";

/**

1. Necessário criar um filtro do mês na renderização da frequência. Sempre renderizar no mês atual.
2. A renderização da frequência deve considerar o mês. Ex: 01 a 31 de dezembro de 2023.
3. Necessidade de crair uma cálculadora de frequência. Ela deve indicar se houve hora extra ou hora a descontar.
4. Ao registrar o ponto, antes deve ser confirmado que já não há um registro no banco de dados para aquela data e no mesmo tipo.
5. Criar uma rota de consulta o ponto dos funcionários. Essa deve conter um botão para gerar um pdf com a frequência (criar template).



 */

const RegistroDePontos = () => {
  const { currentUser } = useFirebaseAuth();
  const [password, setPassword] = useState("");
  const [frequencia, setFrequencia] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [registerType, setRegisterType] = useState(null);

  const userCollections = {
    "ml.barradatijuca.con.3@gmail.com": {
      colName: "rayssafreq",
      name: "Rayssa Rodrigues N Paiva",
    },
    "ml.barradatijuca.con.4@gmail.com": {
      colName: "thaisfreq",
      name: "Thaís Peixoto dos Santos",
    },
    "ml.barradatijuca.tec.1@gmail.com": {
      colName: "ennyfreq",
      name: "Enny Kettery dos Santos Dias",
    },
    "ml.barradatijuca.tec.3@gmail.com": {
      colName: "geysefreq",
      name: "Geyse",
    },
    "ml.barradatijuca.asg@gmail.com": {
      colName: "fabianafreq",
      name: "Fabiana da Cruz Veras",
    },
    "cflaser.barra@gmail.com": { colName: "testefreq", name: "Teste" },
  };

  const handleRegister = async () => {
    if (!password.trim()) {
      alert("Por favor, insira sua senha.");
      return;
    }

    try {
      const credential = EmailAuthProvider.credential(
        currentUser.email,
        password
      );
      await reauthenticateWithCredential(currentUser, credential);

      const userInfo = userCollections[currentUser.email];
      if (!userInfo) {
        alert("Usuário não autorizado.");
        return;
      }

      const { colName, name } = userInfo;
      const collectionRef = collection(db, colName);
      const timestamp = new Date();

      const registro = {
        email: currentUser.email,
        nome: name,
        data: timestamp.toISOString().split("T")[0],
        horario: timestamp.toTimeString().split(" ")[0],
        tipo: registerType,
      };

      await addDoc(collectionRef, registro);
      alert(
        `${
          registerType === "entrada" ? "Entrada" : "Saída"
        } registrada com sucesso.`
      );
      setShowModal(false);
      setPassword("");
      setRegisterType(null);
    } catch (error) {
      console.error("Erro ao registrar ponto:", error);
      alert("Erro ao registrar ponto. Verifique sua senha e tente novamente.");
    }
  };

  const fetchFrequencia = async () => {
    setLoading(true);
    try {
      const role = currentUser.customClaims?.role;
      const userInfo = userCollections[currentUser.email];
      let registros = [];

      if (role === "user") {
        if (!userInfo) {
          alert("Usuário não autorizado para visualizar frequência.");
          return;
        }
        const { colName } = userInfo;

        const q = query(
          collection(db, colName),
          where("email", "==", currentUser.email)
        );
        const querySnapshot = await getDocs(q);
        registros = querySnapshot.docs.map((doc) => doc.data());
      } else {
        const allCollections = Object.values(userCollections).map(
          ({ colName }) => query(collection(db, colName))
        );

        const snapshots = await Promise.all(
          allCollections.map((q) => getDocs(q))
        );
        registros = snapshots.flatMap((snapshot) =>
          snapshot.docs.map((doc) => doc.data())
        );
      }

      setFrequencia(registros);
    } catch (error) {
      console.error("Erro ao buscar frequência:", error);
      alert("Erro ao buscar frequência. Verifique os logs para mais detalhes.");
    } finally {
      setLoading(false);
    }
  };

  const groupedFrequencia = frequencia.reduce((acc, registro) => {
    const { data, nome, horario, tipo } = registro;

    if (!acc[data]) {
      acc[data] = { data, nome, entrada: null, saida: null };
    }

    if (tipo === "entrada") {
      acc[data].entrada = horario;
    } else if (tipo === "saida") {
      acc[data].saida = horario;
    }

    return acc;
  }, {});

  useEffect(() => {
    if (showTable) {
      fetchFrequencia();
    }
  }, [showTable]);

  return (
    <div className="registro-pontos">
      <h2 className="registro-pontos-title">Registro de Pontos</h2>
      <div className="registro-pontos-buttons">
        <button
          className="registro-pontos-button"
          onClick={() => {
            setRegisterType("entrada");
            setShowModal(true);
          }}
        >
          Registrar Entrada
        </button>
        <button
          className="registro-pontos-button"
          onClick={() => {
            setRegisterType("saida");
            setShowModal(true);
          }}
        >
          Registrar Saída
        </button>
        <button
          className="registro-pontos-button"
          onClick={() => setShowTable(!showTable)}
        >
          {showTable ? "Ocultar Tabela" : "Mostrar Frequência"}
        </button>
      </div>

      {showTable && (
        <div className="registro-pontos-table-container">
          {loading ? (
            <p className="registro-pontos-loading">Carregando...</p>
          ) : (
            <table className="registro-pontos-table">
              <thead>
                <tr>
                  <th className="registro-pontos-table-header">Data</th>
                  <th className="registro-pontos-table-header">Nome</th>
                  <th className="registro-pontos-table-header">Entrada</th>
                  <th className="registro-pontos-table-header">Saída</th>
                </tr>
              </thead>
              <tbody>
                {Object.values(groupedFrequencia)
                  .sort((a, b) => new Date(a.data) - new Date(b.data))
                  .map((registro, index) => (
                    <tr key={index} className="registro-pontos-table-row">
                      <td className="registro-pontos-table-cell">
                        {registro.data}
                      </td>
                      <td className="registro-pontos-table-cell">
                        {registro.nome}
                      </td>
                      <td className="registro-pontos-table-cell">
                        {registro.entrada || "Não registrado"}
                      </td>
                      <td className="registro-pontos-table-cell">
                        {registro.saida || "Não registrado"}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          )}
        </div>
      )}

      {showModal && (
        <div className="registro-pontos-modal-overlay">
          <div className="registro-pontos-modal">
            <div className="registro-pontos-modal-content">
              <h3 className="registro-pontos-modal-title">
                Confirmação de Senha
              </h3>
              <label className="registro-pontos-modal-label">Senha:</label>
              <input
                className="registro-pontos-modal-input"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Digite sua senha"
              />
              <div className="registro-pontos-modal-actions">
                <button
                  className="registro-pontos-modal-button"
                  onClick={handleRegister}
                >
                  Confirmar
                </button>
                <button
                  className="registro-pontos-modal-button"
                  onClick={() => setShowModal(false)}
                >
                  Cancelar
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RegistroDePontos;
