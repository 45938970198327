import React, { useState, useEffect } from "react";
import { db } from "../config/firebase";
import {
  collection,
  addDoc,
  query,
  where,
  getDocs,
  updateDoc,
  doc,
} from "firebase/firestore";
import { getIdTokenResult } from "firebase/auth";
import { useFirebaseAuth } from "./FirebaseAuthContext";
import Modal from "react-modal";
import "./TaskList.css";

Modal.setAppElement("#root");

const TaskList = () => {
  const { currentUser } = useFirebaseAuth();
  const [tasks, setTasks] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [userClaims, setUserClaims] = useState(null);
  const [selectedTask, setSelectedTask] = useState(null);
  const [newComment, setNewComment] = useState("");
  const [commentModalOpen, setCommentModalOpen] = useState(false);
  const [showCompleted, setShowCompleted] = useState(false); // Novo estado
  const [newTask, setNewTask] = useState({
    owner: "",
    description: "",
    dueDate: "",
    status: "Pendente",
    priority: "",
    comments: "",
    solicitante: "", // Novo campo para o solicitante
  });

  const currentDate = () => {
    const now = new Date();
    return `${String(now.getDate()).padStart(2, "0")}/${String(
      now.getMonth() + 1
    ).padStart(2, "0")}`;
  };

  // Opções de usuários responsáveis pelas tarefas
  const userOptions = [
    /*{ value: "kiTDkAkbUhZ3WjFfIfyWujyRzdX2", label: "Ana Claudia" },
    { value: "uGRGMbH66lTGixjuHzy3lvQtNx52", label: "Carol" },*/
    { value: "zGRay1ANHFZoDObAr4bllzJjc1F3", label: "Enny" },
    { value: "eVZjXlRT5dajNGhoTdyJBTfFjau2", label: "Isabella" },
    { value: "qdJ9AM200FTcXYranft4z8L11WE3", label: "Rayssa" },
    { value: "xWJDplZUCbbajCEgFXD6SdTQPkK2", label: "Thaís" },
    { value: "5mnR4nPJPxUgr3d6FdSp3dXzbjj1", label: "Dione" },
    { value: "YvgRjnUgawc2hdnl9LrSjRgOVKf1", label: "Clara" },
    { value: "Fo1yuMUd8FSa7mtjFYFiabihXxo1", label: "Felipe" },
  ];

  // Opções de solicitantes
  const applicatOptions = [
    { value: "YvgRjnUgawc2hdnl9LrSjRgOVKf1", label: "Clara" },
    { value: "Fo1yuMUd8FSa7mtjFYFiabihXxo1", label: "Felipe" },
    { value: "5mnR4nPJPxUgr3d6FdSp3dXzbjj1", label: "Dione" },
  ];

  // Encontrar o nome do solicitante atual com base no UID
  const currentUserOption = userOptions.find(
    (user) => user.value === currentUser?.uid
  );

  // Obtém as claims do usuário logado
  useEffect(() => {
    const fetchClaims = async () => {
      if (currentUser) {
        try {
          const idTokenResult = await getIdTokenResult(currentUser, true);
          setUserClaims(idTokenResult.claims);
        } catch (error) {
          console.error("Erro ao buscar claims:", error);
        }
      }
      setLoading(false);
    };

    fetchClaims();
  }, [currentUser]);

  useEffect(() => {
    const fetchTasks = async () => {
      if (!currentUser || !userClaims) return;

      let q;

      try {
        if (userClaims.role === "user") {
          // Busca o usuário atual no userOptions
          const currentUserOption = userOptions.find(
            (user) => user.value === currentUser.uid
          );

          // Verifica se currentUserOption é válido
          if (!currentUserOption) {
            console.error("Usuário não encontrado em userOptions.");
            return;
          }

          // Filtra as tarefas atribuídas ao usuário atual
          q = query(
            collection(db, "tasks"),
            where("owner", "==", currentUserOption.label)
          );
        } else {
          // Admins e gerentes veem todas as tarefas
          q = query(collection(db, "tasks"));
        }

        // Busca as tarefas no banco de dados
        const querySnapshot = await getDocs(q);
        const tasksData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Verifica e atualiza o status das tarefas com base nas datas
        await checkTaskDates(tasksData);

        // Atualiza o estado das tarefas
        setTasks(tasksData);
      } catch (error) {
        console.error("Erro ao buscar tarefas:", error);
      }
    };

    if (userClaims) {
      fetchTasks();
    }
  }, [currentUser, userClaims]); // Remove dependência desnecessária de currentUserOption

  const handleCreateTask = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, "tasks"), {
        ...newTask,
        owner:
          userClaims.role === "user" ? currentUserOption.label : newTask.owner,
        solicitante: currentUserOption?.label, // Define o solicitante como o logado
        createdBy: currentUser.uid,
      });
      setIsModalOpen(false);
      setNewTask({
        owner: "",
        description: "",
        dueDate: "",
        status: "Pendente",
        priority: "",
        comments: "",
        solicitante: "",
      });
    } catch (error) {
      console.error("Erro ao criar tarefa:", error);
    }
  };

  const toggleTaskStatus = async (taskId, currentStatus) => {
    try {
      const taskRef = doc(db, "tasks", taskId);
      const updatedStatus =
        currentStatus === "Pendente" ? "Concluída" : "Pendente";

      // Atualiza no Firestore
      await updateDoc(taskRef, { status: updatedStatus });

      // Atualiza o estado local para refletir a mudança imediatamente
      setTasks((prevTasks) =>
        prevTasks.map((task) =>
          task.id === taskId ? { ...task, status: updatedStatus } : task
        )
      );
    } catch (error) {
      console.error("Erro ao atualizar status da tarefa:", error);
    }
  };

  // Filtra as tarefas com base no estado showCompleted
  const filteredTasks = tasks.filter((task) =>
    showCompleted ? task.status === "Concluída" : task.status !== "Concluída"
  );

  if (loading) {
    return <div>Carregando...</div>;
  }

  if (!currentUser || !userClaims) {
    return <div>Erro: Usuário não autenticado ou sem permissões.</div>;
  }

  const handleAddComment = async () => {
    try {
      const taskRef = doc(db, "tasks", selectedTask.id);
      const newComments = `${
        selectedTask.comments ? selectedTask.comments + "\n" : ""
      }[${currentDate()}] ${newComment}`;
      await updateDoc(taskRef, { comments: newComments });
      setCommentModalOpen(false);
      setNewComment("");
      setTasks((prev) =>
        prev.map((task) =>
          task.id === selectedTask.id
            ? { ...task, comments: newComments }
            : task
        )
      );
    } catch (error) {
      console.error("Erro ao adicionar comentário:", error);
    }
  };

  const taskClass = (dueDate) => {
    const today = new Date();
    const formattedToday = today.toISOString().split("T")[0]; // Converte hoje para "YYYY-MM-DD"
    const formattedTaskDate = new Date(dueDate).toISOString().split("T")[0]; // Converte data limite

    if (formattedTaskDate < formattedToday) return "task-overdue"; // Atrasada
    if (formattedTaskDate === formattedToday) return "task-today"; // Hoje
    return ""; // Futuras
  };

  const checkTaskDates = async (tasks) => {
    const today = new Date().toISOString().split("T")[0]; // Data de hoje

    for (let task of tasks) {
      const taskDate = new Date(task.dueDate).toISOString().split("T")[0];

      if (taskDate < today && task.status !== "Atrasada") {
        const taskRef = doc(db, "tasks", task.id);
        await updateDoc(taskRef, { status: "Atrasada" });
        task.status = "Atrasada";
      }
    }
    setTasks([...tasks]);
  };
  console.log("currentUserOption.label:", currentUserOption?.label);

  return (
    <section id="task-list">
      <h2>Lista de Tarefas</h2>
      {(userClaims.role === "manager" || userClaims.role === "admin") && (
        <button
          className="create-task-button"
          onClick={() => setIsModalOpen(true)}
        >
          Criar Tarefa
        </button>
      )}
      <button
        onClick={() => setShowCompleted((prev) => !prev)}
        className="toggle-tasks-btn"
      >
        {showCompleted
          ? "Mostrar Tarefas Abertas"
          : "Mostrar Tarefas Concluídas"}
      </button>
      <ul>
        {filteredTasks.map((task) => (
          <li key={task.id} className={taskClass(task.dueDate)}>
            <div className="task-row">
              <p>
                <strong>{task.description}</strong>
              </p>
            </div>
            <div className="task-row details">
              <span>Status: {task.status}</span>
              <span>Prioridade: {task.priority}</span>
              <span>Data Limite: {task.dueDate}</span>
              <span>Responsável: {task.owner}</span>
              <span>Solicitante: {task.solicitante}</span>
            </div>

            {/* Linha 3 - Comentários */}
            <div className="task-row comments">
              <p>
                <em>Observação:</em> <br />
                {task.comments?.split("\n").map((line, index) => (
                  <span key={index}>{line}</span>
                ))}
              </p>
            </div>
            <div className="task-buttons">
              <button onClick={() => toggleTaskStatus(task.id, task.status)}>
                {task.status === "Pendente"
                  ? "Concluir Tarefa"
                  : "Reabrir Tarefa"}
              </button>
              {currentUserOption?.label?.trim().toLowerCase() ===
                task.owner?.trim().toLowerCase() && (
                <button
                  onClick={() => {
                    setSelectedTask(task);
                    setCommentModalOpen(true);
                  }}
                >
                  Incluir Comentário
                </button>
              )}
            </div>
          </li>
        ))}
      </ul>
      <Modal
        isOpen={commentModalOpen}
        onRequestClose={() => setCommentModalOpen(false)}
        overlayClassName="task-modal-overlay"
        className="task-modal-content"
      >
        <h2>Adicionar Comentário</h2>
        <textarea
          rows="4"
          value={newComment}
          onChange={(e) => setNewComment(e.target.value)}
          placeholder="Digite seu comentário aqui..."
        />
        <button onClick={handleAddComment}>Salvar Comentário</button>
        <button onClick={() => setCommentModalOpen(false)}>Cancelar</button>
      </Modal>

      {/* Modal */}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        overlayClassName="task-modal-overlay"
        className="task-modal-content"
      >
        <h2>Criar Nova Tarefa</h2>
        <form onSubmit={handleCreateTask}>
          {userClaims.role !== "user" && (
            <label>
              Usuário:
              <select
                value={newTask.owner}
                onChange={(e) =>
                  setNewTask({ ...newTask, owner: e.target.value })
                }
                required
              >
                <option value="">Selecione um responsável</option>
                {userOptions.map((user) => (
                  <option key={user.value} value={user.label}>
                    {user.label}
                  </option>
                ))}
              </select>
            </label>
          )}
          <label>
            Descrição:
            <textarea
              value={newTask.description}
              onChange={(e) =>
                setNewTask({ ...newTask, description: e.target.value })
              }
              required
            />
          </label>
          <label>
            Prioridade:
            <select
              value={newTask.priority}
              onChange={(e) =>
                setNewTask({ ...newTask, priority: e.target.value })
              }
              required
            >
              <option value="">Selecione uma prioridade</option>
              <option value="Alta⚡⚡⚡">Alta⚡⚡⚡</option>
              <option value="Media⚡⚡">Media⚡⚡</option>
              <option value="Baixa⚡">Baixa⚡</option>
            </select>
          </label>
          <label>
            Data Limite:
            <input
              type="date"
              value={newTask.dueDate}
              onChange={(e) =>
                setNewTask({ ...newTask, dueDate: e.target.value })
              }
              required
            />
          </label>
          <button type="submit">Salvar Tarefa</button>
          <button type="button" onClick={() => setIsModalOpen(false)}>
            Cancelar
          </button>
        </form>
      </Modal>
    </section>
  );
};

export default TaskList;
