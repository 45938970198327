import React, { useState } from "react";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../config/firebase";
import "./AdsTracking.css";

const AdsTracking = () => {
  const [filters, setFilters] = useState({
    source: "",
    medium: "",
    campaign: "",
    content: "",
  });
  const [leads, setLeads] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  const handleSearch = async () => {
    setLoading(true);
    try {
      let queryRef = collection(db, "utm_tracking");

      // Aplica os filtros opcionais
      if (filters.source)
        queryRef = query(queryRef, where("source", "==", filters.source));
      if (filters.medium)
        queryRef = query(queryRef, where("medium", "==", filters.medium));
      if (filters.campaign)
        queryRef = query(queryRef, where("campaign", "==", filters.campaign));
      if (filters.content)
        queryRef = query(queryRef, where("content", "==", filters.content));

      const snapshot = await getDocs(queryRef);

      if (snapshot.empty) {
        alert("Nenhum lead encontrado.");
        setLeads([]);
      } else {
        const results = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setLeads(results);
      }
    } catch (error) {
      console.error("Erro ao consultar leads:", error);
      alert("Erro ao consultar leads. Verifique os logs.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="ads-tracking">
      <h2>Rastreador de Anúncios</h2>
      <div className="filters">
        <label>
          Fonte (utm_source):
          <input
            type="text"
            name="source"
            value={filters.source}
            onChange={handleChange}
          />
        </label>
        <label>
          Meio (utm_medium):
          <input
            type="text"
            name="medium"
            value={filters.medium}
            onChange={handleChange}
          />
        </label>
        <label>
          Campanha (utm_campaign):
          <input
            type="text"
            name="campaign"
            value={filters.campaign}
            onChange={handleChange}
          />
        </label>
        <label>
          Conteúdo (utm_content):
          <input
            type="text"
            name="content"
            value={filters.content}
            onChange={handleChange}
          />
        </label>
        <button onClick={handleSearch} disabled={loading}>
          {loading ? "Consultando..." : "Consultar"}
        </button>
      </div>
      <div className="results">
        {leads.length > 0 ? (
          <table>
            <thead>
              <tr>
                <th>Fonte</th>
                <th>Meio</th>
                <th>Campanha</th>
                <th>Conteúdo</th>
                <th>Data</th>
              </tr>
            </thead>
            <tbody>
              {leads.map((lead) => (
                <tr key={lead.id}>
                  <td>{lead.source}</td>
                  <td>{lead.medium}</td>
                  <td>{lead.campaign}</td>
                  <td>{lead.content}</td>
                  <td>{new Date(lead.timestamp).toLocaleString("pt-BR")}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>Nenhum resultado encontrado.</p>
        )}
      </div>
    </div>
  );
};

export default AdsTracking;
