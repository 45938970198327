// Administrativo.js
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { db } from "../config/firebase"; // Certifique-se de usar o caminho correto
import { collection, getDocs } from "firebase/firestore";
import { useFirebaseAuth } from "./FirebaseAuthContext";
import {
  calculateSalesMetricsForVendor,
  calculateTotalStoreMetrics,
} from "./ConsultaVendas";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import "./Administrativo.css";

// Registrar os componentes do gráfico no Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const Administrativo = () => {
  const { currentUser } = useFirebaseAuth(); // Usa o hook correto

  const metasMensais = {
    "2024-06": 44100,
    "2024-07": 44100,
    "2024-08": 44100,
    "2024-09": 44100,
    "2024-10": 44100,
    "2024-11": 50000,
    "2024-12": 50000,
    "2025-01": 50000,
  };

  // Mapeamento de e-mails para nomes de vendedores
  const emailToVendorName = {
    "ml.barradatijuca.con.1@gmail.com": "Ana Claudia",
    "ml.barradatijuca.con.2@gmail.com": "Carolina",
    "ml.barradatijuca.con.3@gmail.com": "Rayssa",
    "ml.barradatijuca.con.4@gmail.com": "Thaís",
    "ml.barradatijuca.tec.1@gmail.com": "Enny",
    "ml.barradatijuca.tec.2@gmail.com": "Isabella",
  };

  // Lista de e-mails dos gestores
  const gestoresEmails = [
    "felipe.camarinha@gmail.com",
    "clara.vaccarii@gmail.com",
    "ml.barradatijuca.ger@gmail.com",
  ];

  const [sales, setSales] = useState([]);
  const [indiceMensal, setIndiceMensal] = useState("0.00");
  const [salesMetrics, setSalesMetrics] = useState({
    indice: "0.00",
    totalSales: "0.00",
  });
  const [storeMetrics, setStoreMetrics] = useState({
    indiceLoja: "0.00",
    totalSales: "0.00",
  });

  useEffect(() => {
    const fetchSales = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "sales"));
        const salesData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setSales(salesData);
      } catch (error) {
        console.error("Erro ao buscar vendas:", error);
      }
    };

    fetchSales();
  }, []);

  useEffect(() => {
    if (currentUser && sales.length > 0) {
      // Obtem o nome do vendedor com base no e-mail do usuário logado
      const vendorName = emailToVendorName[currentUser.email];

      if (vendorName) {
        // Calcula os dados individuais do vendedor
        const metrics = calculateSalesMetricsForVendor(
          sales,
          vendorName,
          metasMensais
        );
        setSalesMetrics(metrics);
      } else {
        console.error(
          "Usuário logado não encontrado no mapeamento de vendedores."
        );
      }

      // Calcula os dados totais da loja para gestores
      if (gestoresEmails.includes(currentUser.email)) {
        const totalMetrics = calculateTotalStoreMetrics(sales, metasMensais);
        setStoreMetrics(totalMetrics);
      }
    }
  }, [currentUser, sales]);

  // Função para capitalizar a primeira letra do username
  const capitalizeFirstLetter = (string) => {
    if (!string) return ""; // Caso o username seja undefined ou null
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  // Dados para o gráfico
  const userChartData = {
    labels: ["Meta Mensal"],
    datasets: [
      {
        label: "Atingido (%)",
        data: [parseFloat(salesMetrics.indice)],
        backgroundColor: "rgba(15, 100, 146, 0.8)",
      },
      {
        label: "Faltante (%)",
        data: [100 - parseFloat(salesMetrics.indice)],
        backgroundColor: "rgba(255, 99, 132, 0.8)",
      },
    ],
  };

  // Dados do gráfico total da loja
  const storeChartData = {
    labels: ["Meta da Loja"],
    datasets: [
      {
        label: "Atingido (%)",
        data: [parseFloat(storeMetrics.indiceLoja)],
        backgroundColor: "rgba(15, 100, 146, 0.8)",
      },
      {
        label: "Faltante (%)",
        data: [100 - parseFloat(storeMetrics.indiceLoja)],
        backgroundColor: "rgba(255, 99, 132, 0.8)",
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Índice de acompanhamento de Venda/Meta",
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        max: 100,
        title: {
          display: true,
          text: "Progresso (%)",
        },
      },
    },
  };

  return (
    <section id="administrativo-container">
      <div className="administrativo" style={{ flex: 1 }}>
        <h2>Painel Administrativo</h2>
        <p>
          Olá{" "}
          {currentUser?.email === "clara.vaccarii@gmail.com"
            ? "Clara"
            : currentUser?.email === "felipe.camarinha@gmail.com"
            ? "Felipe"
            : currentUser?.email === "ml.barradatijuca.con.3@gmail.com"
            ? "Rayssa"
            : currentUser?.email === "ml.barradatijuca.con.4@gmail.com"
            ? "Thaís"
            : currentUser?.email === "ml.barradatijuca.tec.1@gmail.com"
            ? "Enny"
            : currentUser?.email === "ml.barradatijuca.tec.2@gmail.com"
            ? "Isabella"
            : currentUser?.email === "ml.barradatijuca.ger@gmail.com"
            ? "Dione"
            : null}{" "}
          , Seja bem-vinda ao painel administrativo. <br />
          Escolha uma das opções abaixo para acessar as funcionalidades
          disponíveis:
        </p>
        <ul>
          {/* Links específicos para técnicos */}
          {["cflaser.barra@gmail.com"].includes(currentUser?.email) && (
            <>
              <li>
                <Link to="/pontoeletronico" aria-label="Ponto Eletrônico">
                  Ponto Eletrônico:
                </Link>
                <p>
                  Gerencie o ponto eletrônico de maneira prática e eficiente.
                </p>
              </li>
              <li>
                <Link
                  to="/rastreadoranuncios"
                  aria-label="Rastreador de Anuncios"
                >
                  Rastreador de Anuncios:
                </Link>
                <p>Rastreie os anuncios de maneira prática e eficiente.</p>
              </li>
            </>
          )}
          {/* Links específicos para técnicos */}
          {[
            "ml.barradatijuca.tec.1@gmail.com",
            "ml.barradatijuca.tec.2@gmail.com",
          ].includes(currentUser?.email) && (
            <>
              <li>
                <Link
                  to="/calculadoraaplicadoras"
                  aria-label="Calculadora para Aplicadoras"
                >
                  Calculadora Aplicadoras:
                </Link>
                <p>
                  Gerencie os orçamentos de aplicadoras de maneira prática e
                  eficiente.
                </p>
              </li>
              <li>
                <Link
                  to="/intercorrencia"
                  aria-label="Fichas de Intercorrência"
                >
                  Fichas de Intercorrência:
                </Link>
                <p>
                  Crie e gerencie fichas de intercorrência de maneira prática e
                  eficiente.
                </p>
              </li>
            </>
          )}
          {/* Links específicos para Consultoras */}
          {[
            "ml.barradatijuca.con.3@gmail.com",
            "ml.barradatijuca.con.4@gmail.com",
          ].includes(currentUser?.email) && (
            <>
              <li>
                <Link
                  to="/calculadoraconsultoras"
                  aria-label="Calculadora para Consultoras"
                >
                  Calculadora Consultoras:
                </Link>
                <p>
                  Realize os orçamentos de consultoras de maneira prática e
                  eficiente.
                </p>
              </li>
              <li>
                <Link to="/orcamentos" aria-label="Orçamentos">
                  Orçamentos:
                </Link>
                <p>Visualize os orçamentos de maneira prática e eficiente.</p>
              </li>
            </>
          )}
          {/* Links específicos para Administradores */}
          {[
            "felipe.camarinha@gmail.com",
            "clara.vaccarii@gmail.com",
            "ml.barradatijuca.ger@gmail.com",
          ].includes(currentUser?.email) && (
            <>
              <li>
                <Link
                  to="/calculadoraaplicadoras"
                  aria-label="Calculadora para Aplicadoras"
                >
                  Calculadora Aplicadoras:
                </Link>
                <p>
                  Realize os orçamentos de aplicadoras de maneira prática e
                  eficiente.
                </p>
              </li>
              <li>
                <Link
                  to="/calculadoraconsultoras"
                  aria-label="Calculadora para Consultoras"
                >
                  Calculadora Consultoras:
                </Link>
                <p>
                  Gerencie os orçamentos de consultoras de maneira prática e
                  eficiente.
                </p>
              </li>
              <li>
                <Link to="/orcamentos" aria-label="Orçamentos">
                  Orçamentos:
                </Link>
                <p>Visualize os orçamentos de maneira prática e eficiente.</p>
              </li>
              <li>
                <Link to="/controledegestao" aria-label="Controle de Gestão">
                  Controle de Lembretes:
                </Link>
                <p>Gerencie os lembretes seus e de todos os funcionários.</p>
              </li>
              <li>
                <Link to="/consultavendas" aria-label="Consulta de Vendas">
                  Gestão de Vendas:
                </Link>
                <p>Gerencie todas as vendas, gráficos, índices e muito mais.</p>
              </li>
              <li>
                <Link
                  to="/intercorrencia"
                  aria-label="Fichas de Intercorrência"
                >
                  Fichas de Intercorrência:
                </Link>
                <p>
                  Crie e gerencie fichas de intercorrência de maneira prática e
                  eficiente.
                </p>
              </li>
            </>
          )}
          {/* Links específicos para Administradores */}
          {["felipe.camarinha@gmail.com", "clara.vaccarii@gmail.com"].includes(
            currentUser?.email
          ) && (
            <>
              <li>
                <Link to="/gestaoestoque" aria-label="Gestão de Estoque">
                  Gestão de Estoque:
                </Link>
                <p>
                  Gerencie o estoque de produtos de maneira prática e eficiente.
                </p>
              </li>
              <li>
                <Link to="/folhasalarial" aria-label="Folha Salarial">
                  Folha Salarial:
                </Link>
                <p>Gerencie a folha salarial de maneira prática e eficiente.</p>
              </li>
            </>
          )}
          {/* Links específicos para Funcionárias */}
          {[
            "ml.barradatijuca.con.3@gmail.com",
            "ml.barradatijuca.con.4@gmail.com",
            "ml.barradatijuca.tec.1@gmail.com",
            "ml.barradatijuca.tec.2@gmail.com",
          ].includes(currentUser?.email) && (
            <>
              <li>
                <Link to="/user-dashboard" aria-label="Dashboard do usuário">
                  Dashboard do Usuário:
                </Link>
                <p>Visualize suas anotações/lembretes.</p>
              </li>
            </>
          )}

          <li>
            <Link to="/gestaoleads" aria-label="Gestão de leads">
              Gestão de Leads:
            </Link>
            <p>Cadastre e Acompanhe os leads de maneira integrada.</p>
          </li>

          <li>
            <Link to="/vendasporcliente" aria-label="Vendas por Cliente">
              Vendas por Cliente:
            </Link>
            <p>Consulte as vendas dos clientes.</p>
          </li>

          <li>
            <Link
              to="/anamnesedepilacao"
              aria-label="Formulário de anamnese de depilação"
            >
              Anamnese Depilação:
            </Link>
            <p>Envie o formulário de anamnese específicos para depilação.</p>
          </li>
          <li>
            <Link to="/anamneseomer" aria-label="Formulário de anamnese Ômer">
              Anamnese Ômer:
            </Link>
            <p>
              Envie o formulário de anamnese relacionado ao procedimento Ômer.
            </p>
          </li>
          <li>
            <Link to="/listadetarefas" aria-label="Lista de Tarefas">
              Lista de Tarefas:
            </Link>
            <p>Gerencie as tarefas de maneira prática e eficiente.</p>
          </li>
          <li>
            <a
              href="https://app.whatssync.com.br"
              target="_blank" // Abre o link em uma nova aba
              rel="noopener noreferrer" // Melhora a segurança
              aria-label="Whatssync"
            >
              WhatsSync:
            </a>
            <p>Entre em contato com nossos clientes, leads e outros.</p>
          </li>
          <li>
            <a
              href="https://app.bellesoftware.com.br"
              target="_blank" // Abre o link em uma nova aba
              rel="noopener noreferrer" // Melhora a segurança
              aria-label="bellesoftware"
            >
              Belle:
            </a>
            <p>Sistema de gestão de vendas, serviços e agendamentos.</p>
          </li>
          {["felipe.camarinha@gmail.com"].includes(currentUser?.email) && (
            <>
              <li>
                <Link to="/setup-claims" aria-label="Configurar Claims">
                  Configurar Claims:
                </Link>
                <p>Configurar claims de maneira prática e eficiente.</p>
              </li>
            </>
          )}
        </ul>
      </div>
      {/* Div do gráfico e informações (lado direito) */}
      {[
        "ml.barradatijuca.con.3@gmail.com",
        "ml.barradatijuca.con.4@gmail.com",
        "ml.barradatijuca.tec.1@gmail.com",
        "ml.barradatijuca.tec.2@gmail.com",
      ].includes(currentUser?.email) && (
        <div className="grafico" style={{ flex: 1 }}>
          {/* Gráfico do índice */}
          <Bar data={userChartData} options={chartOptions} />
          {/* Informação do total de vendas abaixo do gráfico */}
          <p style={{ textAlign: "center", marginTop: "20px" }}>
            Você vendeu <strong>R$ {salesMetrics.totalSales}</strong> até agora.
            <br />
            Faltam <strong>R$ {salesMetrics.faltante}</strong> para atingir a
            meta mensal.
          </p>
        </div>
      )}
      {gestoresEmails.includes(currentUser?.email) && (
        <div className="grafico">
          <Bar data={storeChartData} options={chartOptions} />
          <p>
            Total Vendido no Mês: <strong>R$ {storeMetrics.totalSales}</strong>
            <br />
            Faltam <strong>R$ {storeMetrics.faltante}</strong> para atingir a
            meta da loja.
          </p>
        </div>
      )}
    </section>
  );
};

export default Administrativo;
