import React, { useState, useEffect } from "react";
import { db } from "../config/firebase";
import {
  collection,
  addDoc,
  doc,
  updateDoc,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { useFirebaseAuth } from "./FirebaseAuthContext";
import Select from "react-select";
import "./ControleGestao.css";

const ControleGestao = () => {
  const { currentUser } = useFirebaseAuth();
  const [annotations, setAnnotations] = useState([]);
  const [newAnnotation, setNewAnnotation] = useState({
    nome: "",
    telefone: "",
    dataContato: "",
    lembrete: "",
    etiqueta: [],
    anotacaoUsuario: "", // Campo para a anotação do usuário
    status: "Aberto",
  });
  const [isEditing, setIsEditing] = useState(null); // Estado para controlar edição
  const [editedNote, setEditedNote] = useState(""); // Estado para a anotação editada
  const [filteredUser, setFilteredUser] = useState("");
  const [selectedLog, setSelectedLog] = useState(null); // Estado para controlar o log selecionado
  const [isLogModalOpen, setIsLogModalOpen] = useState(false); // Estado para exibir modal de log
  const [selectedCheckboxId, setSelectedCheckboxId] = useState(null); // Estado para checkbox selecionada

  const [confirmClose, setConfirmClose] = useState({
    visible: false,
    id: null,
  });

  const [filters, setFilters] = useState({
    celular: "",
    dataContatoOriginal: "",
    dataLembrete: "",
    etiqueta: "",
    status: "",
  });

  const [isAdding, setIsAdding] = useState(false);

  const [editingEtiquetaId, setEditingEtiquetaId] = useState(null); // ID do lembrete em edição
  const [editedEtiqueta, setEditedEtiqueta] = useState([]); // Etiquetas editadas temporariamente

  const etiquetaOptions = [
    { value: "Lead Whatsapp", label: "Lead Whatsapp" },
    { value: "Lead Instagram", label: "Lead Instagram" },
    { value: "Depilação-Laser", label: "Depilação-Laser" },
    { value: "Remoc.Tattoo", label: "Remoc.Tattoo" },
    { value: "Clareamento", label: "Clareamento" },
    { value: "Urgente", label: "Urgente" },
    { value: "Inadimplente", label: "Inadimplente" },
    { value: "Revenda", label: "Revenda" },
    { value: "Agendamento", label: "Agendamento" },
    { value: "Contrato", label: "Contrato" },
    { value: "Intercorrência", label: "Intercorrência" },
    { value: "Nota Pessoal", label: "Nota Pessoal" },
  ];

  const statusOptions = [
    { value: "Aberto", label: "Aberto" },
    { value: "Fechado", label: "Fechado" },
    { value: "Reaberto", label: "Reaberto" },
  ];

  // Opções de usuários
  const userOptions = [
    { value: "YvgRjnUgawc2hdnl9LrSjRgOVKf1", label: "Clara" },
    /*{ value: "kiTDkAkbUhZ3WjFfIfyWujyRzdX2", label: "Ana Claudia" },
    { value: "uGRGMbH66lTGixjuHzy3lvQtNx52", label: "Carol" },*/
    { value: "zGRay1ANHFZoDObAr4bllzJjc1F3", label: "Enny" },
    { value: "eVZjXlRT5dajNGhoTdyJBTfFjau2", label: "Isabella" },
    { value: "xWJDplZUCbbajCEgFXD6SdTQPkK2", label: "Thaís" },
    { value: "qdJ9AM200FTcXYranft4z8L11WE3", label: "Rayssa" },
    { value: "Fo1yuMUd8FSa7mtjFYFiabihXxo1", label: "Felipe" },
    { value: "5mnR4nPJPxUgr3d6FdSp3dXzbjj1", label: "Dione" },
  ];

  // Encontrar o nome do usuário atual com base no ID
  const currentUserOption = userOptions.find(
    (user) => user.value === currentUser.uid
  );

  // Função para obter o nome do usuário com base no userId
  const getUserName = (userId) => {
    const user = userOptions.find((option) => option.value === userId);
    return user ? user.label : "Usuário não encontrado"; // Retorna "Usuário não encontrado" se o ID não corresponder a nenhum usuário
  };

  // Função para buscar as anotações do usuário logado em tempo real
  useEffect(() => {
    if (currentUser) {
      const annotationsRef = collection(db, "annotations");

      // Consulta para buscar todas as anotações
      const q = annotationsRef;

      // Usar onSnapshot para ouvir mudanças em tempo real
      const unsubscribe = onSnapshot(q, (snapshot) => {
        const updatedAnnotations = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setAnnotations(updatedAnnotations);
      });

      // Limpar o listener quando o componente for desmontado
      return () => unsubscribe();
    }
  }, [currentUser]);

  const hoje = new Date();
  const hojeStr = hoje.toLocaleDateString("en-CA"); // Hoje

  const handlePhoneChange = (e) => {
    let input = e.target.value.replace(/\D/g, "");
    if (input.length === 0) {
      input = ""; // Deixe o campo vazio para que não apareça "()" quando não há números
    }
    if (input.length > 2) {
      input = `(${input.slice(0, 2)}) ${input.slice(2, 7)}-${input.slice(
        7,
        11
      )}`;
    }

    setNewAnnotation({ ...newAnnotation, telefone: input });
  };

  const addAnnotation = async (e) => {
    e.preventDefault();

    // Verifique se o telefone está vazio e defina como "N/A" se necessário
    const telefone =
      newAnnotation.telefone.trim() === "" ? "N/A" : newAnnotation.telefone;

    const formattedPhone =
      telefone !== "N/A" ? telefone.replace(/\D/g, "") : "";

    // Obter a data atual no formato desejado
    const today = new Date();
    const formattedDate = `${String(today.getDate()).padStart(2, "0")}/${String(
      today.getMonth() + 1
    ).padStart(2, "0")}`;

    // Prefixar a anotação do usuário com a data atual
    const anotacaoUsuarioComData = `${formattedDate} - ${newAnnotation.anotacaoUsuario}`;

    await addDoc(collection(db, "annotations"), {
      ...newAnnotation,
      telefone:
        telefone === "N/A"
          ? "N/A"
          : `(${formattedPhone.slice(0, 2)}) ${formattedPhone.slice(
              2,
              7
            )}-${formattedPhone.slice(7, 11)}`,
      etiqueta: newAnnotation.etiqueta.map((option) => option.value),
      userId: currentUser.uid,
      anotacaoUsuario: anotacaoUsuarioComData,
      status: "Aberto", // Define o status inicial como "Aberto"
    });

    setNewAnnotation({
      nome: "",
      telefone: "",
      dataContato: "",
      lembrete: "",
      etiqueta: [],
      anotacaoUsuario: "",
    });
  };

  const handleStatusChange = (id, newStatus) => {
    if (newStatus === "Fechado") {
      setConfirmClose({ visible: true, id });
    } else {
      updateStatus(id, newStatus);
    }
  };

  const updateStatus = async (id, status) => {
    const annotationRef = doc(db, "annotations", id);
    await updateDoc(annotationRef, { status });
  };

  const confirmStatusClose = async () => {
    if (confirmClose.id) {
      await updateStatus(confirmClose.id, "Fechado");
      setConfirmClose({ visible: false, id: null });
    }
  };

  // Função para salvar a anotação com data
  const handleEditToggle = (id) => {
    setIsEditing(id);
    setEditedNote(""); // Ensure the modal opens with an empty textarea
  };

  const handleSaveAnnotation = async (id) => {
    const annotationRef = doc(db, "annotations", id);
    const formattedDate = `${String(new Date().getDate()).padStart(
      2,
      "0"
    )}/${String(new Date().getMonth() + 1).padStart(2, "0")}`;

    // Verificar qual usuário está fazendo a edição com base no currentUser.uid
    // Verificar qual usuário está fazendo a edição com base no currentUser.uid
    const adminName =
      currentUser.uid === "YvgRjnUgawc2hdnl9LrSjRgOVKf1"
        ? "Clara"
        : currentUser.uid === "Fo1yuMUd8FSa7mtjFYFiabihXxo1"
        ? "Felipe"
        : ""; // Adicione um nome padrão caso o ID não corresponda a nenhum usuário

    const newNoteWithDate = `${formattedDate} - ${adminName}: ${editedNote}`;

    // Fetch current data to ensure it's up-to-date
    const currentAnnotation = annotations.find(
      (annotation) => annotation.id === id
    );
    const updatedAnnotationContent = currentAnnotation.anotacaoAdmin
      ? `${currentAnnotation.anotacaoAdmin}\n${newNoteWithDate}`
      : newNoteWithDate;

    await updateDoc(annotationRef, {
      anotacaoAdmin: updatedAnnotationContent, // Atualiza o campo anotacaoAdmin
    });

    setIsEditing(null); // Fecha o modal
    setEditedNote(""); // Limpa o conteúdo do textarea
  };

  // Calcula as datas mínima e máxima permitidas
  const today = new Date();
  const maxDate = today.toLocaleDateString("en-CA"); // Hoje
  const minDate = new Date(
    today.setDate(today.getDate() - 60)
  ).toLocaleDateString("en-CA"); // 30 dias atrás

  const maxDate2 = new Date();
  maxDate2.setDate(maxDate2.getDate() + 30);
  const maxDate2Str = maxDate2.toLocaleDateString("en-CA");

  const minDate2 = new Date();
  minDate2.setDate(minDate2.getDate() + 1);
  const minDate2Str = minDate2.toLocaleDateString("en-CA");

  const handleFilterChange = (field, value) => {
    setFilters({ ...filters, [field]: value });
  };

  const filteredAnnotations = annotations.filter((annotation) => {
    const isStatusMatched =
      (filters.status === "" &&
        (annotation.status === "Aberto" || annotation.status === "Reaberto")) ||
      (filters.status !== "" && annotation.status === filters.status);

    // Filtrar por usuário somente se filteredUser tiver um valor selecionado
    const isUserMatched =
      filteredUser === "" || annotation.userId === filteredUser;

    return (
      isUserMatched &&
      (filters.celular === "" ||
        annotation.telefone.includes(filters.celular)) &&
      (filters.dataContatoOriginal === "" ||
        annotation.dataContato === filters.dataContatoOriginal) &&
      (filters.dataLembrete === "" ||
        annotation.lembrete === filters.dataLembrete) &&
      (filters.etiqueta === "" ||
        annotation.etiqueta.includes(filters.etiqueta)) &&
      isStatusMatched
    );
  });

  // Função para salvar as etiquetas editadas no Firestore
  const handleSaveEtiqueta = async (id) => {
    const annotationRef = doc(db, "annotations", id);
    const newEtiquetaValues = editedEtiqueta.map((option) => option.value);

    await updateDoc(annotationRef, { etiqueta: newEtiquetaValues });

    setEditingEtiquetaId(null); // Sair do modo de edição
    setEditedEtiqueta([]); // Limpar as etiquetas editadas temporariamente
  };

  // Função para iniciar a edição das etiquetas
  const startEditingEtiqueta = (id, etiqueta) => {
    setEditingEtiquetaId(id); // Define o ID do lembrete em edição
    setEditedEtiqueta(
      etiqueta.map((value) =>
        etiquetaOptions.find((opt) => opt.value === value)
      )
    );
  };

  // Calcular contagens de anotações por status
  const openCount = annotations.filter(
    (annotation) => annotation.status === "Aberto"
  ).length;
  const closedCount = annotations.filter(
    (annotation) => annotation.status === "Fechado"
  ).length;
  const reopenedCount = annotations.filter(
    (annotation) => annotation.status === "Reaberto"
  ).length;

  const hojeCount = new Date().toISOString().split("T")[0]; // Data de hoje no formato AAAA-MM-DD

  const overdueCount = annotations.filter(
    (annotation) =>
      annotation.status !== "Fechado" &&
      annotation.lembrete < hojeCount &&
      (annotation.status === "Aberto" || annotation.status === "Reaberto")
  ).length;

  // Estado para controlar a exibição do modal "Adicionar Anotação para Usuário"
  const [isAddingForUser, setIsAddingForUser] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(""); // Estado para o usuário selecionado
  const [adminComment, setAdminComment] = useState(""); // Estado para o comentário admin

  // Função para adicionar anotação para um usuário selecionado
  const addAnnotationForUser = async (e) => {
    e.preventDefault();

    if (!selectedUserId) {
      alert("Por favor, selecione um usuário.");
      return;
    }

    // Verifique se o telefone está vazio e defina como "N/A" se necessário
    const telefone =
      newAnnotation.telefone.trim() === "" ? "N/A" : newAnnotation.telefone;

    const formattedPhone =
      telefone !== "N/A" ? telefone.replace(/\D/g, "") : "";

    // Obter a data atual no formato desejado
    const today = new Date();
    const formattedDate = `${String(today.getDate()).padStart(2, "0")}/${String(
      today.getMonth() + 1
    ).padStart(2, "0")}`;

    const adminName =
      currentUser.uid === "YvgRjnUgawc2hdnl9LrSjRgOVKf1"
        ? "Clara"
        : currentUser.uid === "Fo1yuMUd8FSa7mtjFYFiabihXxo1"
        ? "Felipe"
        : ""; // Adicione um nome padrão caso o ID não corresponda a nenhum usuário

    await addDoc(collection(db, "annotations"), {
      ...newAnnotation,
      telefone:
        telefone === "N/A"
          ? "N/A"
          : `(${formattedPhone.slice(0, 2)}) ${formattedPhone.slice(
              2,
              7
            )}-${formattedPhone.slice(7, 11)}`,
      etiqueta: newAnnotation.etiqueta.map((option) => option.value),
      userId: selectedUserId, // Salva a anotação para o usuário selecionado
      anotacaoAdmin: `${formattedDate} - ${adminName}: ${adminComment}`, // Comentário do admin com data
      status: "Aberto",
    });

    // Limpa os campos e fecha o modal
    setNewAnnotation({
      nome: "",
      telefone: "",
      dataContato: "",
      lembrete: "",
      etiqueta: [],
      anotacaoUsuario: "",
    });
    setAdminComment("");
    setSelectedUserId("");
    setIsAddingForUser(false);
  };

  const handleLogClick = (log) => {
    setSelectedLog(log || []); // Define o log selecionado ou vazio
    setIsLogModalOpen(true); // Abre o modal
  };

  const closeLogModal = () => {
    setIsLogModalOpen(false);
    setSelectedLog(null); // Reseta o estado do log
  };

  return (
    <div className="controle-gestao-container">
      <h2>Painel de Anotações da Maislaser - Barra da Tijuca</h2>
      <div className="contador-anotacoes">
        <div className="contador-box">
          <p className="contador-titulo">Anotações Abertas</p>
          <p className="contador-numero">{openCount}</p>
        </div>
        <div className="contador-box">
          <p className="contador-titulo">Anotações Fechadas</p>
          <p className="contador-numero">{closedCount}</p>
        </div>
        <div className="contador-box">
          <p className="contador-titulo">Anotações Reabertas</p>
          <p className="contador-numero">{reopenedCount}</p>
        </div>
        <div
          className={overdueCount > 0 ? "contador-box-overdue" : "contador-box"}
        >
          <p className="contador-titulo">Anotações Vencidas</p>
          <p className="contador-numero">{overdueCount}</p>
        </div>
      </div>
      <div
        className="botoes-adicionar"
        style={{
          display: "flex",
          justifyContent: "center",
          gap: "10px",
          marginBottom: "20px",
        }}
      >
        <button
          className="adicionar-anotacao"
          onClick={() => setIsAdding(true)}
        >
          Adicionar Anotação Própria
        </button>

        <button
          className="adicionar-anotacao-para-usuario"
          onClick={() => setIsAddingForUser(true)}
        >
          Adicionar Anotação para Usuário
        </button>
      </div>

      {isAdding && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h3>Adicionar Anotação Própria</h3>
            <form onSubmit={addAnnotation}>
              <label>Nome:</label>
              <input
                type="text"
                placeholder="Nome"
                value={newAnnotation.nome}
                onChange={(e) =>
                  setNewAnnotation({ ...newAnnotation, nome: e.target.value })
                }
                required
              />
              <label>Telefone:</label>
              <input
                type="text"
                placeholder="(XX) XXXXX-XXXX"
                value={newAnnotation.telefone}
                onChange={handlePhoneChange}
                maxLength="15"
              />
              <label>Data Contato Original:</label>
              <input
                type="date"
                placeholder="Data Contato Original"
                value={newAnnotation.dataContato}
                min={minDate}
                max={maxDate}
                onChange={(e) =>
                  setNewAnnotation({
                    ...newAnnotation,
                    dataContato: e.target.value,
                  })
                }
                required
              />
              <label>Data Lembrete:</label>
              <input
                type="date"
                placeholder="Data do Lembrete"
                value={newAnnotation.lembrete}
                min={minDate2Str}
                max={maxDate2Str}
                onChange={(e) =>
                  setNewAnnotation({
                    ...newAnnotation,
                    lembrete: e.target.value,
                  })
                }
                required
              />
              <label>Etiqueta:</label>
              <Select
                isMulti
                options={etiquetaOptions}
                value={newAnnotation.etiqueta}
                onChange={(selectedOptions) =>
                  setNewAnnotation({
                    ...newAnnotation,
                    etiqueta: selectedOptions,
                  })
                }
              />
              <label>Anotação do Usuário:</label>
              <textarea
                placeholder="Anotação do Usuário"
                value={newAnnotation.anotacaoUsuario}
                onChange={(e) =>
                  setNewAnnotation({
                    ...newAnnotation,
                    anotacaoUsuario: e.target.value,
                  })
                }
              />
              <button type="submit">Adicionar Anotação</button>
              <button type="button" onClick={() => setIsAdding(false)}>
                Sair
              </button>
            </form>
          </div>
        </div>
      )}
      {/* Modal para adicionar anotação para um usuário específico */}
      {isAddingForUser && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h3>Adicionar Anotação para Usuário</h3>
            <form onSubmit={addAnnotationForUser}>
              <label>Selecione o Usuário:</label>
              <Select
                options={userOptions}
                value={userOptions.find(
                  (option) => option.value === selectedUserId
                )}
                onChange={(option) => setSelectedUserId(option.value)}
                placeholder="Escolha o usuário"
                isClearable
              />
              <label>Nome:</label>
              <input
                type="text"
                placeholder="Nome"
                value={newAnnotation.nome}
                onChange={(e) =>
                  setNewAnnotation({ ...newAnnotation, nome: e.target.value })
                }
                required
              />
              <label>Telefone:</label>
              <input
                type="text"
                placeholder="(XX) XXXXX-XXXX"
                value={newAnnotation.telefone}
                onChange={handlePhoneChange}
                maxLength="15"
              />
              <label>Data Contato Original:</label>
              <input
                type="date"
                placeholder="Data Contato Original"
                value={newAnnotation.dataContato}
                min={minDate}
                max={maxDate}
                onChange={(e) =>
                  setNewAnnotation({
                    ...newAnnotation,
                    dataContato: e.target.value,
                  })
                }
                required
              />
              <label>Data Lembrete:</label>
              <input
                type="date"
                placeholder="Data do Lembrete"
                value={newAnnotation.lembrete}
                min={minDate2Str}
                max={maxDate2Str}
                onChange={(e) =>
                  setNewAnnotation({
                    ...newAnnotation,
                    lembrete: e.target.value,
                  })
                }
                required
              />
              <label>Etiqueta:</label>
              <Select
                isMulti
                options={etiquetaOptions}
                value={newAnnotation.etiqueta}
                onChange={(selectedOptions) =>
                  setNewAnnotation({
                    ...newAnnotation,
                    etiqueta: selectedOptions,
                  })
                }
              />
              <label>Comentário Admin:</label>
              <textarea
                placeholder="Comentário do Admin"
                value={adminComment}
                onChange={(e) => setAdminComment(e.target.value)}
                required
              />
              <button type="submit">Adicionar Anotação para Usuário</button>
              <button type="button" onClick={() => setIsAddingForUser(false)}>
                Cancelar
              </button>
            </form>
          </div>
        </div>
      )}

      {/* Filter Inputs */}
      {/* Filter Inputs */}
      <div className="filters">
        <div className="select-container">
          <label>Filtrar por Usuário:</label>
          <Select
            placeholder="Filtrar por Usuário"
            options={userOptions}
            value={userOptions.find((option) => option.value === filteredUser)}
            onChange={(selectedOption) =>
              setFilteredUser(selectedOption ? selectedOption.value : "")
            }
            isClearable
          />
        </div>
        <div>
          <label>Filtrar por Celular:</label>
          <input
            type="text"
            placeholder="Filtrar por Celular"
            value={filters.celular}
            onChange={(e) => handleFilterChange("celular", e.target.value)}
          />
        </div>

        <div>
          <label>Filtrar por Data Contato Original:</label>
          <input
            type="date"
            placeholder="Data Contato Original"
            value={filters.dataContatoOriginal}
            onChange={(e) =>
              handleFilterChange("dataContatoOriginal", e.target.value)
            }
          />
        </div>

        <div>
          <label>Filtrar por Data Lembrete:</label>
          <input
            type="date"
            placeholder="Data Lembrete"
            value={filters.dataLembrete}
            onChange={(e) => handleFilterChange("dataLembrete", e.target.value)}
          />
        </div>

        <div className="select-container">
          <label>Filtrar por Etiqueta:</label>
          <Select
            placeholder="Filtrar por Etiqueta"
            options={etiquetaOptions}
            value={etiquetaOptions.find(
              (option) => option.value === filters.etiqueta
            )}
            onChange={(selectedOption) =>
              handleFilterChange(
                "etiqueta",
                selectedOption ? selectedOption.value : ""
              )
            }
            isClearable
          />
        </div>

        <div className="select-container">
          <label>Filtrar por Status:</label>
          <Select
            placeholder="Filtrar por Status"
            options={statusOptions}
            value={statusOptions.find(
              (option) => option.value === filters.status
            )}
            onChange={(selectedOption) =>
              handleFilterChange(
                "status",
                selectedOption ? selectedOption.value : ""
              )
            }
            isClearable
          />
        </div>
      </div>

      {/* Lista de Anotações */}
      <h2>Lista de Anotações</h2>
      <div className="table-responsive">
        <table>
          <thead>
            <tr>
              <th>Usuário</th>
              <th>Nome</th>
              <th>Telefone</th>
              <th>Data Contato Original</th>
              <th>Data Lembrete</th>
              <th>Etiqueta</th>
              <th>Anotação Usuário</th>
              <th>Comentário Admin</th>
              <th>Editar</th>
              <th>Status</th>
              <th>Log</th>
            </tr>
          </thead>
          <tbody>
            {filteredAnnotations
              .sort((a, b) => {
                // Convert date strings to Date objects for comparison
                const dateA = new Date(a.lembrete);
                const dateB = new Date(b.lembrete);
                return dateA - dateB; // Sort from oldest to newest
              })
              .map((annotation) => {
                const lembreteClass =
                  annotation.status !== "Fechado" &&
                  annotation.lembrete < hojeStr
                    ? "row-past-due"
                    : annotation.status !== "Fechado" &&
                      annotation.lembrete === hojeStr
                    ? "row-today"
                    : "";
                return (
                  <tr key={annotation.id} className={lembreteClass}>
                    <td>{getUserName(annotation.userId)}</td>{" "}
                    {/* Exibe o nome do usuário */}
                    <td>{annotation.nome}</td>
                    <td>{annotation.telefone}</td>
                    <td>{annotation.dataContato}</td>
                    <td>{annotation.lembrete}</td>
                    <td>
                      {editingEtiquetaId === annotation.id ? (
                        <div>
                          <Select
                            isMulti
                            options={etiquetaOptions}
                            value={editedEtiqueta}
                            onChange={(selectedOptions) =>
                              setEditedEtiqueta(selectedOptions)
                            }
                          />
                          <button
                            onClick={() => handleSaveEtiqueta(annotation.id)}
                          >
                            Salvar
                          </button>
                          <button onClick={() => setEditingEtiquetaId(null)}>
                            Cancelar
                          </button>
                        </div>
                      ) : (
                        <span
                          onClick={() =>
                            startEditingEtiqueta(
                              annotation.id,
                              annotation.etiqueta
                            )
                          }
                        >
                          {Array.isArray(annotation.etiqueta)
                            ? annotation.etiqueta.join(", ")
                            : annotation.etiqueta || ""}
                        </span>
                      )}
                    </td>
                    <td>
                      <pre
                        style={{
                          whiteSpace: "pre-wrap",
                          wordWrap: "break-word",
                        }}
                      >
                        {annotation.anotacaoUsuario}
                      </pre>
                    </td>
                    <td>{annotation.anotacaoAdmin}</td>
                    <td>
                      <input
                        type="checkbox"
                        checked={isEditing === annotation.id}
                        onChange={() =>
                          handleEditToggle(
                            annotation.id,
                            annotation.anotacaoAdmin
                          )
                        }
                      />
                    </td>
                    <td>
                      <select
                        value={annotation.status || ""}
                        onChange={(e) =>
                          handleStatusChange(annotation.id, e.target.value)
                        }
                      >
                        {annotation.status === "Aberto"
                          ? // If the status is "Aberto", show all options
                            statusOptions.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))
                          : // If the status is not "Aberto", show options excluding "Aberto"
                            statusOptions
                              .filter((option) => option.value !== "Aberto")
                              .map((option) => (
                                <option key={option.value} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                      </select>
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        checked={selectedCheckboxId === annotation.id} // Marca se for o checkbox clicado
                        onChange={() =>
                          handleLogClick(annotation.log, annotation.id)
                        }
                      />
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      {isEditing && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h3>Editar Comentário Admin</h3>
            <textarea
              value={editedNote}
              onChange={(e) => setEditedNote(e.target.value)}
              placeholder="Digite seu novo comentário aqui..."
              rows={5}
            />
            <button onClick={() => handleSaveAnnotation(isEditing)}>
              Salvar
            </button>
            <button onClick={() => setIsEditing(null)}>Fechar</button>
          </div>
        </div>
      )}
      {confirmClose.visible && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h3>Confirmar Fechamento</h3>
            <p>Tem certeza de que deseja fechar esta anotação?</p>
            <button onClick={confirmStatusClose}>Sim</button>
            <button
              onClick={() => setConfirmClose({ visible: false, id: null })}
            >
              Cancelar
            </button>
          </div>
        </div>
      )}
      {/* Modal para exibir o log */}
      {isLogModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h3>Log de Alterações</h3>
            {selectedLog && selectedLog.length > 0 ? (
              <ul>
                {selectedLog.map((logEntry, index) => (
                  <li key={index}>
                    <strong>Data:</strong> {logEntry.date} <br />
                    <strong>Ação:</strong> {logEntry.action} <br />
                    <strong>Usuário:</strong> {logEntry.user}
                  </li>
                ))}
              </ul>
            ) : (
              <p>Nenhum log registrado.</p>
            )}
            <button onClick={closeLogModal}>Fechar</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ControleGestao;
