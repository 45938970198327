import React, { useEffect, useState, useRef } from "react";
import { db } from "../config/firebase"; // Certifique-se de que o caminho está correto
import {
  collection,
  addDoc,
  doc,
  updateDoc,
  getDocs,
} from "firebase/firestore";
import { useFirebaseAuth } from "./FirebaseAuthContext";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import "./ConsultaVendas.css"; // Certifique-se de que o CSS está no caminho correto
import ExcelJS from "exceljs";
import { useNavigate } from "react-router-dom";
import { Line } from "react-chartjs-2"; // Importe o componente Line

// Função para calcular o índice de venda/meta de um vendedor em um mês específico
export const calculateSalesMetricsForVendor = (
  sales,
  vendedor,
  metasMensais
) => {
  const today = new Date();
  const currentMonth = `${today.getUTCFullYear()}-${String(
    today.getUTCMonth() + 1
  ).padStart(2, "0")}`;

  // Filtrar vendas do vendedor no mês atual
  const vendasMensais = sales.filter((sale) => {
    const saleDate = new Date(sale.data);
    const saleMonth = `${saleDate.getUTCFullYear()}-${String(
      saleDate.getUTCMonth() + 1
    ).padStart(2, "0")}`;
    return saleMonth === currentMonth && sale.vendedor === vendedor;
  });

  // Total de vendas no mês atual
  const totalSales = vendasMensais.reduce(
    (sum, sale) => sum + (sale.valor || 0),
    0
  );

  // Meta mensal completa
  const metaMensalCompleta = metasMensais[currentMonth] || 0;

  // Valor faltante para atingir a meta
  const faltante = metaMensalCompleta > 0 ? metaMensalCompleta - totalSales : 0;

  // Índice de venda/meta (%)
  const indice =
    metaMensalCompleta > 0
      ? ((totalSales / metaMensalCompleta) * 100).toFixed(2)
      : "0.00";

  // Retorna os valores calculados
  return {
    indice, // Índice em %
    totalSales: totalSales.toFixed(2), // Total vendido (formatado em R$)
    faltante: faltante.toFixed(2), // Quanto falta (formatado em R$)
  };
};

export const calculateTotalStoreMetrics = (sales, metasMensais) => {
  const today = new Date();
  const currentMonth = `${today.getUTCFullYear()}-${String(
    today.getUTCMonth() + 1
  ).padStart(2, "0")}`;

  // Filtrar todas as vendas do mês atual
  const vendasMensais = sales.filter((sale) => {
    const saleDate = new Date(sale.data);
    const saleMonth = `${saleDate.getUTCFullYear()}-${String(
      saleDate.getUTCMonth() + 1
    ).padStart(2, "0")}`;
    return saleMonth === currentMonth;
  });

  // Soma o valor total das vendas do mês
  const totalSales = vendasMensais.reduce(
    (sum, sale) => sum + (sale.valor || 0),
    0
  );

  // Meta mensal completa
  const metaMensalCompleta = metasMensais[currentMonth] || 0;

  // Valor faltante para atingir a meta da loja
  const faltante =
    metaMensalCompleta > totalSales ? metaMensalCompleta - totalSales : 0;

  // Calcula o índice total da loja (vendas em relação à meta completa)
  const indiceLoja =
    metaMensalCompleta > 0
      ? ((totalSales / metaMensalCompleta) * 100).toFixed(2)
      : "0.00";

  return {
    totalSales: totalSales.toFixed(2), // Total vendido (formatado em R$)
    faltante: faltante.toFixed(2), // Quanto falta para atingir a meta (formatado em R$)
    indiceLoja, // Índice de vendas em relação à meta (%)
  };
};

function ConsultaVenda() {
  const [sales, setSales] = useState([]);
  const [filteredSales, setFilteredSales] = useState([]);
  const [loading, setLoading] = useState(true);
  const { currentUser } = useFirebaseAuth(); // Obtém o usuário autenticado
  const [monthlySums, setMonthlySums] = useState([]); // Dados do gráfico
  const [selectedVendedor, setSelectedVendedor] = useState("");
  const [activeChart, setActiveChart] = useState(null); // Estado único para gráficos
  const [selectedMonth, setSelectedMonth] = useState(""); // Ex.: "2024-11"
  const [originSums, setOriginSums] = useState([]); // Dados do gráfico por origem
  const [procedureSums, setProcedureSums] = useState([]); // Estado para gráfico por procedimento
  const [selectedProcedureMonth, setSelectedProcedureMonth] = useState(""); // Mês selecionado
  const [discountData, setDiscountData] = useState([]); // Dados do gráfico
  const [selectedDiscountVendor, setSelectedDiscountVendor] = useState(""); // Vendedora filtrada
  const [paymentData, setPaymentData] = useState({
    accumulatedPercentages: [],
    monthlyArray: [],
  });
  const [vendaMetaIndices, setVendaMetaIndices] = useState([]);
  const [indiceMensal, setIndiceMensal] = useState([]);
  const [selectedIndiceVendedor, setSelectedIndiceVendedor] = useState("Loja");
  const [showNewSaleModal, setShowNewSaleModal] = useState(false); // Controle do modal
  const [newSale, setNewSale] = useState({
    nome: "",
    cpf: "",
    data: "",
    procedimento: "",
    valor: "",
    descontoTotal: "",
    valorCheio: "",
    vendedor: "",
    origem: "",
    entrada: "",
    formaPgtEntrada: "",
    saldo: "",
    formaPgtSaldo: "",
    parcelas: "",
    servico1: "",
    servico2: "",
    servico3: "",
    servico4: "",
    servico5: "",
    servico6: "",
    contratoDigital: "",
    termoDigitalOmer: "",
    termoDigitalDepilacao: "",
    idade: "",
    ID: "",
    aniversario: "",
  });
  const [visibleServices, setVisibleServices] = useState(1);
  const [serviceSessions, setServiceSessions] = useState(null); // Estado para armazenar os dados de sessões
  const [showServiceChart, setShowServiceChart] = useState(false); // Controla a exibição do gráfico
  const [salesPerDay, setSalesPerDay] = useState({});
  const [accumulatedSales, setAccumulatedSales] = useState([]);
  const [totalSalesAllTime, setTotalSalesAllTime] = useState(0);
  const [totalCancelledSales, setTotalCancelledSales] = useState(0);
  const [editingRow, setEditingRow] = useState(null);
  const [editedData, setEditedData] = useState({});
  const [pendingCounts, setPendingCounts] = useState({
    contratoDigital: { pending: 0, signed: 0, percentage: 0 },
    termoDigitalOmer: { pending: 0, signed: 0, percentage: 0 },
    termoDigitalDepilacao: { pending: 0, signed: 0, percentage: 0 },
  });

  const salesChartRef = useRef(null);
  const salesPerDayChartRef = useRef(null);
  const originChartRef = useRef(null);
  const discountChartRef = useRef(null);
  const paymentChartRef = useRef(null);
  const procedureChartRef = useRef(null);
  const serviceChartRef = useRef(null);
  const accumulatedSalesChartRef = useRef(null);
  const vendaMetaChartRef = useRef(null);
  const indiceMensalChartRef = useRef(null);

  const handleDownloadChart = (chartRef, chartName) => {
    const chart = chartRef.current;
    if (chart) {
      const chartInstance = chart.chartInstance || chart;
      const currentDate = new Date().toLocaleDateString("pt-BR");
      const fileName = `${chartName}_${currentDate}.png`;

      const canvas = chartInstance.canvas;
      const ctx = canvas.getContext("2d");

      ctx.save();
      ctx.globalCompositeOperation = "destination-over";
      ctx.fillStyle = "white";
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      const link = document.createElement("a");
      link.href = canvas.toDataURL("image/png");
      link.download = fileName;
      link.click();

      ctx.restore();
    } else {
      console.error(
        `A instância do gráfico ${chartName} ou chartInstance é null!`
      );
    }
  };

  const navigate = useNavigate();

  // Funções de edição de vendas
  const handleEditStart = (saleId) => {
    if (currentUserOption?.label === "Felipe") {
      // Verifica se o usuário atual é "Felipe"
      setEditingRow(saleId);
      setEditedData({ ...sales.find((sale) => sale.id === saleId) });
    }
  };

  const handleEditChange = (e, field) => {
    setEditedData({ ...editedData, [field]: e.target.value });
  };

  const handleEditSave = async (saleId) => {
    try {
      // Verifica se o CPF é válido, se estiver sendo editado
      if (editedData.cpf && !validateCPF(editedData.cpf)) {
        alert("CPF inválido! Por favor, insira um CPF válido.");
        return; // Interrompe o salvamento se o CPF for inválido
      }

      const formattedCPF = editedData.cpf ? formatCPF(editedData.cpf) : null; // Formata o CPF se existir

      console.log("Dados a serem salvos:", editedData); // Log dos dados

      const saleRef = doc(db, "sales", saleId);
      await updateDoc(saleRef, {
        ...editedData,
        cpf: formattedCPF || editedData.cpf, // Salva o CPF formatado ou original se não houver alteração
      });

      // Atualiza o estado local com os dados editados
      setSales((prevSales) =>
        prevSales.map((sale) =>
          sale.id === saleId ? { ...sale, ...editedData } : sale
        )
      );

      setEditingRow(null);
      setEditedData({});
    } catch (error) {
      console.error("Erro ao salvar alterações:", error);
      alert("Erro ao salvar. Por favor, tente novamente.");
    }
  };

  const handleEditCancel = () => {
    setEditingRow(null);
    setEditedData({});
  };

  // Função para exportar para Excel
  const downloadExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Vendas");

    // Adiciona cabeçalhos
    worksheet.columns = [
      { header: "Nome", key: "nome", width: 20 },
      { header: "CPF", key: "cpf", width: 15 },
      { header: "Data", key: "data", width: 15 },
      { header: "Tipo Procedimento", key: "procedimento", width: 20 },
      { header: "Valor", key: "valor", width: 15 },
      { header: "Desconto Total", key: "descontoTotal", width: 15 },
      { header: "Valor Cheio", key: "valorCheio", width: 15 },
      { header: "Vendedor", key: "vendedor", width: 15 },
      { header: "Origem", key: "origem", width: 15 },
      { header: "Entrada", key: "entrada", width: 15 },
      { header: "Forma Pgto Entrada", key: "formaPgtEntrada", width: 20 },
      { header: "Saldo", key: "saldo", width: 15 },
      { header: "Forma Pgto Saldo", key: "formaPgtSaldo", width: 20 },
      { header: "Parcelas", key: "parcelas", width: 10 },
      { header: "Serviço 1", key: "servico1", width: 20 }, // Adicione outros serviços conforme necessário
      { header: "Contrato Digital", key: "contratoDigital", width: 15 },
      { header: "Termo Digital Ômer", key: "termoDigitalOmer", width: 20 },
      {
        header: "Termo Digital Depilação",
        key: "termoDigitalDepilacao",
        width: 20,
      },
      { header: "Idade", key: "idade", width: 10 },
      { header: "Aniversário", key: "aniversario", width: 15 },
      { header: "ID", key: "ID", width: 10 }, // Incluindo o ID
    ];

    // Adiciona linhas
    sales.forEach((sale) => {
      worksheet.addRow(sale);
    });

    // Formatação de data (opcional)
    worksheet.getColumn("data").numFmt = "dd/mm/yyyy"; // Ou o formato que você preferir
    worksheet.getColumn("aniversario").numFmt = "dd/mm/yyyy"; // Formato para aniversário

    // Gera o arquivo
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = "vendas.xlsx";
    link.click();
  };

  // Função para calcular o valor total de vendas canceladas
  const calculateTotalCancelledSales = () => {
    return sales.reduce((total, sale) => total + (sale.cancelado || 0), 0);
  };

  // Função para calcular o total de vendas de todos os tempos
  const calculateTotalSalesAllTime = () => {
    return sales.reduce((total, sale) => total + (sale.valor || 0), 0);
  };

  // Função para calcular as pendências
  useEffect(() => {
    const counts = {
      contratoDigital: { pending: 0, signed: 0 },
      termoDigitalOmer: { pending: 0, signed: 0 },
      termoDigitalDepilacao: { pending: 0, signed: 0 },
    };

    sales.forEach((sale) => {
      if (sale.contratoDigital === "Pendente") {
        counts.contratoDigital.pending++;
      } else if (sale.contratoDigital === "Assinado") {
        // Conta como "Assinado"
        counts.contratoDigital.signed++;
      }
      if (sale.termoDigitalOmer === "Pendente") {
        counts.termoDigitalOmer.pending++;
      } else if (sale.termoDigitalOmer === "Assinado") {
        // Conta como "Assinado"
        counts.termoDigitalOmer.signed++;
      }
      if (sale.termoDigitalDepilacao === "Pendente") {
        counts.termoDigitalDepilacao.pending++;
      } else if (sale.termoDigitalDepilacao === "Assinado") {
        // Conta como "Assinado"
        counts.termoDigitalDepilacao.signed++;
      }
    });

    // Calcula as porcentagens
    for (const key in counts) {
      const total = counts[key].pending + counts[key].signed;
      counts[key].percentage =
        total > 0 ? (counts[key].pending / total) * 100 : 0;
    }

    setPendingCounts(counts);
  }, [sales]);

  useEffect(() => {
    const total = calculateTotalSalesAllTime();
    setTotalSalesAllTime(total);
  }, [sales]); // Recalcula quando as vendas mudam

  useEffect(() => {
    const total = calculateTotalCancelledSales();
    setTotalCancelledSales(total);
  }, [sales]); // Recalcula quando as vendas mudam

  // Função para calcular o valor acumulado de vendas por funcionário
  const calculateAccumulatedSalesByEmployee = () => {
    const accumulatedSales = {};

    sales.forEach((sale) => {
      if (!accumulatedSales[sale.vendedor]) {
        accumulatedSales[sale.vendedor] = 0;
      }
      accumulatedSales[sale.vendedor] += sale.valor || 0;
    });

    // Converter para um array de objetos para o Chart.js
    return Object.entries(accumulatedSales)
      .map(([vendedor, total]) => ({
        vendedor,
        total,
      }))
      .sort((a, b) => b.total - a.total); // Ordena por total decrescente
  };

  useEffect(() => {
    const salesData = calculateAccumulatedSalesByEmployee();
    setAccumulatedSales(salesData);
  }, [sales]); // Recalcula quando as vendas mudam

  // Função para calcular vendas por dia trabalhado
  const calculateSalesPerWorkingDay = () => {
    const salesPerDay = {};

    Object.entries(vendedorMetas).forEach(([vendedor, { inicio, fim }]) => {
      const startDate = new Date(inicio);
      const endDate = fim ? new Date(fim) : new Date();

      // Filtra as vendas da vendedora dentro do período
      const filteredSales = sales.filter((sale) => {
        const saleDate = new Date(sale.data);
        return (
          sale.vendedor === vendedor &&
          saleDate >= startDate &&
          saleDate <= endDate
        );
      });

      let totalSales = 0;
      let workingDays = 0;

      // Calcula os dias trabalhados (seg-sex + 2 sábados/mês)
      for (
        let date = new Date(startDate);
        date <= endDate;
        date.setDate(date.getDate() + 1)
      ) {
        const dayOfWeek = date.getDay();
        if (dayOfWeek >= 1 && dayOfWeek <= 5) {
          workingDays++;
        } else if (dayOfWeek === 6) {
          // Sábado
          // Verifica se é um dos dois sábados do mês
          const firstSaturday = new Date(
            date.getFullYear(),
            date.getMonth(),
            1
          );
          while (firstSaturday.getDay() !== 6) {
            firstSaturday.setDate(firstSaturday.getDate() + 1);
          }
          const secondSaturday = new Date(firstSaturday);
          secondSaturday.setDate(secondSaturday.getDate() + 7);

          if (
            date.getTime() === firstSaturday.getTime() ||
            date.getTime() === secondSaturday.getTime()
          ) {
            workingDays++;
          }
        }
      }

      filteredSales.forEach((sale) => {
        totalSales += sale.valor || 0;
      });

      salesPerDay[vendedor] = workingDays > 0 ? totalSales / workingDays : 0;
    });

    // Ordena as vendas por dia trabalhado em ordem decrescente
    const sortedSalesPerDay = Object.entries(salesPerDay)
      .sort(([, valueA], [, valueB]) => valueB - valueA)
      .reduce((obj, [key, value]) => {
        obj[key] = value;
        return obj;
      }, {});

    return sortedSalesPerDay;
  };
  // useEffect para calcular e atualizar o estado
  useEffect(() => {
    const salesData = calculateSalesPerWorkingDay();
    setSalesPerDay(salesData); // Atualiza o estado com os novos dados
  }, [sales]); // Recalcula quando as vendas mudam

  // Opções de usuários
  const userOptions = [
    { value: "YvgRjnUgawc2hdnl9LrSjRgOVKf1", label: "Clara" },
    { value: "Fo1yuMUd8FSa7mtjFYFiabihXxo1", label: "Felipe" },
    { value: "5mnR4nPJPxUgr3d6FdSp3dXzbjj1", label: "Dione" },
  ];

  // Encontrar o nome do usuário atual com base no ID

  // Encontrar o nome do usuário atual com base no ID
  const currentUserOption = currentUser
    ? userOptions.find((user) => user.value === currentUser.uid)
    : null;

  useEffect(() => {
    if (!currentUserOption) {
      console.error("Usuário não autenticado ou opção não encontrada.");
      navigate("/loginfirebase");
    }
  }, [currentUserOption, navigate]);
  // Lista de tratamentos
  const treatments = [
    "Abdômen",
    "Aréola",
    "Axila",
    "Barba Completa",
    "Buço",
    "Clareamento Íntimo",
    "Controle Oleosidade",
    "Coxa",
    "Dedo",
    "Estrias",
    "Face Lateral",
    "Foliculite",
    "Glúteo",
    "Hiperpigmentação",
    "Interglúteos",
    "Joelho",
    "Linha Alba",
    "Meia Perna",
    "Melasma",
    "Perna Inteira",
    "Pescoço",
    "Posterior Total",
    "Rejuvenescimento Facial",
    "Remoção de Micropigmentação",
    "Remoção de Tatuagem",
    "Rosto",
    "Queixo",
    "Tórax",
    "Virilha + Base pênis",
    "Virilha Biquini",
    "Virilha Completa",
    "Virilha Completa + Perianal",
    "Virilha Íntima",
  ];

  // Função para validar o CPF completo
  const validateCPF = (cpf) => {
    // Remove caracteres não numéricos
    cpf = cpf.replace(/[^\d]+/g, "");

    if (cpf.length !== 11 || /^(\d)\1{10}$/.test(cpf)) {
      return false; // Verifica se todos os números são iguais ou se o tamanho é diferente de 11
    }

    let soma = 0;
    let resto;

    // Calcula o primeiro dígito verificador
    for (let i = 1; i <= 9; i++) {
      soma += parseInt(cpf.substring(i - 1, i)) * (11 - i);
    }
    resto = (soma * 10) % 11;
    if (resto === 10 || resto === 11) resto = 0;
    if (resto !== parseInt(cpf.substring(9, 10))) return false;

    soma = 0;

    // Calcula o segundo dígito verificador
    for (let i = 1; i <= 10; i++) {
      soma += parseInt(cpf.substring(i - 1, i)) * (12 - i);
    }
    resto = (soma * 10) % 11;
    if (resto === 10 || resto === 11) resto = 0;
    if (resto !== parseInt(cpf.substring(10, 11))) return false;

    return true; // CPF válido
  };

  const formatCPF = (cpf) => {
    if (cpf.length !== 11) return cpf; // Retorna sem formatar se o CPF não tiver 11 números
    return `${cpf.slice(0, 3)}.${cpf.slice(3, 6)}.${cpf.slice(
      6,
      9
    )}-${cpf.slice(9, 11)}`;
  };

  // Função para calcular as sessões por serviço
  const calculateSessionsByService = (sales) => {
    const sessionsByService = {};

    // Itera pelas vendas
    sales.forEach((sale) => {
      // Itera por cada serviço (servico1 a servico6)
      for (let i = 1; i <= 6; i++) {
        const service = sale[`servico${i}`];
        if (service) {
          // Regex para extrair o número de sessões e o nome do serviço
          const match = service.match(/^(\d+)\s+(Sessão|Sessões)\s+(.+)$/);
          if (match) {
            const [, numSessions, , serviceName] = match; // Corrigido
            const sessions = parseInt(numSessions);

            // Soma as sessões para o mesmo serviço
            if (sessionsByService[serviceName]) {
              sessionsByService[serviceName] += sessions;
            } else {
              sessionsByService[serviceName] = sessions;
            }
          }
        }
      }
    });

    // Ordena os serviços pelo número de sessões em ordem decrescente
    const sortedSessions = Object.entries(sessionsByService)
      .sort(([, a], [, b]) => b - a) // Ordena pelo número de sessões (b - a)
      .reduce((acc, [serviceName, sessions]) => {
        acc[serviceName] = sessions;
        return acc;
      }, {});

    setServiceSessions(sortedSessions); // Atualiza o estado com os resultados
  };

  // Função para adicionar um novo serviçorvico ao formulário
  const handleServiceChange = (index, field, value) => {
    setNewSale((prev) => {
      const currentService = prev[`servico${index + 1}`] || "";

      // Extrair partes da string atual (se existente)
      const [sessions, sessionString, ...treatmentParts] =
        currentService.split(" ");
      const currentTreatment = treatmentParts.join(" ");

      let updatedService = currentService;

      if (field === "sessions") {
        const sessionStr = value === "1" ? "Sessão" : "Sessões";
        updatedService = `${value} ${sessionStr} ${currentTreatment}`;
      } else if (field === "treatment") {
        updatedService = `${sessions || "1"} ${
          sessionString || "Sessão"
        } ${value}`;
      }

      return {
        ...prev,
        [`servico${index + 1}`]: updatedService.trim(),
      };
    });
  };

  // Função para buscar vendas
  const fetchSales = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "sales"));
      const salesData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setSales(salesData);
    } catch (error) {
      console.error("Erro ao buscar vendas:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSales();
  }, []);

  // Função para calcular a idade com base na data de aniversário
  const calculateAge = (birthDate) => {
    const today = new Date();
    const birth = new Date(birthDate);
    let age = today.getFullYear() - birth.getFullYear();
    const monthDiff = today.getMonth() - birth.getMonth();
    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birth.getDate())
    ) {
      age--;
    }
    return age;
  };

  // Atualiza o estado de idade ao alterar a data de aniversário
  const handleBirthdayChange = (date) => {
    setNewSale({
      ...newSale,
      aniversario: date,
      idade: calculateAge(date),
    });
  };

  // Função para adicionar uma nova venda
  const handleAddSale = async (e) => {
    e.preventDefault();

    // Verifica se o CPF é válido
    if (!validateCPF(newSale.cpf)) {
      alert("CPF inválido! Por favor, insira um CPF válido.");
      return; // Interrompe o envio se o CPF for inválido
    }
    try {
      const formattedCPF = formatCPF(newSale.cpf); // Formata o CPF
      // Calcula o saldo automaticamente
      const saldoCalculado = (newSale.valor || 0) - (newSale.entrada || 0);

      // Recalcula a idade com base na data de aniversário
      const idadeCalculada = newSale.aniversario
        ? calculateAge(newSale.aniversario)
        : "";

      // Calcula o desconto com base no valor e no valorCheio
      const descontoCalculado =
        newSale.valorCheio > 0
          ? ((1 - newSale.valor / newSale.valorCheio) * 100).toFixed(2) + "%"
          : "0%";

      await addDoc(collection(db, "sales"), {
        ...newSale,
        cpf: formattedCPF, // Salva o CPF formatado
        saldo: saldoCalculado,
        descontoTotal: descontoCalculado, // Inclui o desconto calculado
        data: newSale.data, // Já está no formato "YYYY-MM-DD"
        idade: idadeCalculada, // Salva a idade recalculada
      });
      setNewSale({
        nome: "",
        cpf: "",
        data: "",
        procedimento: "",
        valor: "",
        descontoTotal: "",
        valorCheio: "",
        vendedor: "",
        origem: "",
        entrada: "",
        formaPgtEntrada: "",
        saldo: "",
        formaPgtSaldo: "",
        parcelas: "",
        servico1: "",
        servico2: "",
        servico3: "",
        servico4: "",
        servico5: "",
        servico6: "",
        contratoDigital: "",
        termoDigitalOmer: "",
        termoDigitalDepilacao: "",
        idade: "",
        ID: "",
        aniversario: "",
      });
      setVisibleServices(1); // Reseta os serviços visíveis
      setShowNewSaleModal(false); // Fecha o modal
      fetchSales(); // Recarrega as vendas
    } catch (error) {
      console.error("Erro ao adicionar venda:", error);
    }
  };

  // Filtros
  const [filters, setFilters] = useState({
    nome: "",
    dataInicio: "",
    dataFim: "",
    contratoDigital: "",
    termoDigitalOmer: "",
    termoDigitalDepilacao: "",
    vendedor: "",
  });
  const vendedorMetas = {
    "Ana Claudia": {
      proporcao: 1 / 3,
      inicio: "2024-08-15",
      fim: "2024-12-23",
    },
    Camila: { proporcao: 1 / 3, inicio: "2024-06-25", fim: "2024-12-23" },
    Carolina: { proporcao: 1 / 3, inicio: "2024-06-25", fim: "2024-08-08" },
    Enny: { proporcao: 1 / 6, inicio: "2024-06-25" },
    Isabella: { proporcao: 1 / 6, inicio: "2024-06-25" },
    Rayssa: { proporcao: 1 / 3, inicio: "2024-12-23" },
    Thaís: { proporcao: 1 / 3, inicio: "2024-12-23" },
  };

  const metasMensais = {
    "2024-06": 44100,
    "2024-07": 44100,
    "2024-08": 44100,
    "2024-09": 44100,
    "2024-10": 44100,
    "2024-11": 50000,
    "2024-12": 50000,
    "2025-01": 50000,
  };

  const calculateIndiceMensal = () => {
    const indices = [];
    const vendasMensais = {}; // Acumula vendas por vendedor e mês
    const vendasMensaisLoja = {}; // Acumula vendas totais da loja por mês

    sales.forEach((sale) => {
      const saleDate = new Date(sale.data);
      const yearMonth = `${saleDate.getUTCFullYear()}-${String(
        saleDate.getUTCMonth() + 1
      ).padStart(2, "0")}`;

      // Inicializa estruturas de dados
      if (!vendasMensais[yearMonth]) vendasMensais[yearMonth] = {};
      if (!vendasMensais[yearMonth][sale.vendedor])
        vendasMensais[yearMonth][sale.vendedor] = 0;
      if (!vendasMensaisLoja[yearMonth]) vendasMensaisLoja[yearMonth] = 0;

      // Soma valores de vendas
      vendasMensais[yearMonth][sale.vendedor] += sale.valor || 0;
      vendasMensaisLoja[yearMonth] += sale.valor || 0;
    });

    Object.entries(vendedorMetas).forEach(
      ([vendedor, { proporcao, inicio, fim }]) => {
        const inicioDate = new Date(inicio);
        const fimDate = fim ? new Date(fim) : null;

        Object.entries(metasMensais).forEach(([month, meta]) => {
          const monthDate = new Date(`${month}-01`);
          const lastDayOfMonth = new Date(
            monthDate.getUTCFullYear(),
            monthDate.getUTCMonth() + 1,
            0
          );

          if (monthDate <= inicioDate && inicioDate <= lastDayOfMonth) {
            const daysInMonth = lastDayOfMonth.getDate();
            const daysWorked = daysInMonth - inicioDate.getDate();
            const adjustedProporcao = (proporcao * daysWorked) / daysInMonth;
            const proportionalMeta = calculateProportionalMeta(month);
            const metaVendedor = proportionalMeta * adjustedProporcao;
            const vendasVendedor = vendasMensais[month]?.[vendedor] || 0;

            const indice = (vendasVendedor / metaVendedor) * 100;
            indices.push({ vendedor, month, indice: indice.toFixed(2) });
          } else if (
            monthDate >= inicioDate &&
            (!fimDate || monthDate <= fimDate)
          ) {
            const proportionalMeta = calculateProportionalMeta(month);
            const metaVendedor = proportionalMeta * proporcao;
            const vendasVendedor = vendasMensais[month]?.[vendedor] || 0;

            const indice = (vendasVendedor / metaVendedor) * 100;
            indices.push({ vendedor, month, indice: indice.toFixed(2) });
          }
        });
      }
    );

    // Calcula índice acumulado da loja
    Object.entries(metasMensais).forEach(([month, meta]) => {
      const proportionalMeta = calculateProportionalMeta(month);
      const vendasLoja = vendasMensaisLoja[month] || 0;
      const indiceLoja = (vendasLoja / proportionalMeta) * 100;

      indices.push({
        vendedor: "Loja",
        month,
        indice: indiceLoja.toFixed(2),
      });
    });

    return indices;
  };

  const calculateVendaMetaIndices = () => {
    const indices = [];
    const mensal = {};
    const acumulado = {};

    // Acumula vendas por mês e por vendedor
    sales.forEach((sale) => {
      const saleDate = new Date(sale.data);
      const yearMonth = `${saleDate.getUTCFullYear()}-${String(
        saleDate.getUTCMonth() + 1
      ).padStart(2, "0")}`;

      if (!mensal[yearMonth]) mensal[yearMonth] = {};
      if (!mensal[yearMonth][sale.vendedor])
        mensal[yearMonth][sale.vendedor] = 0;

      mensal[yearMonth][sale.vendedor] += sale.valor || 0;

      if (!acumulado[sale.vendedor]) acumulado[sale.vendedor] = 0;
      acumulado[sale.vendedor] += sale.valor || 0;
    });

    // Calcula os índices para cada vendedor
    Object.entries(vendedorMetas).forEach(
      ([vendedor, { proporcao, inicio, fim }]) => {
        const inicioDate = new Date(inicio);
        const fimDate = fim ? new Date(fim) : null;
        let metaAcumulada = 0;

        Object.entries(metasMensais).forEach(([month, meta]) => {
          const monthDate = new Date(`${month}-01`);
          const lastDayOfMonth = new Date(
            monthDate.getUTCFullYear(),
            monthDate.getUTCMonth() + 1,
            0
          );

          // Ajusta proporcionalidade com base nos dias trabalhados no mês
          let daysInMonth = lastDayOfMonth.getDate();
          let daysWorked = daysInMonth;

          if (inicioDate > monthDate && inicioDate <= lastDayOfMonth) {
            daysWorked = daysInMonth - inicioDate.getDate();
          }

          const adjustedProporcao = (proporcao * daysWorked) / daysInMonth;
          const proportionalMeta = meta * adjustedProporcao;
          const vendasVendedor = mensal[month]?.[vendedor] || 0;

          // Garante que o mês seja incluído no cálculo, mesmo que as vendas sejam 0
          if (!mensal[month]) mensal[month] = {};
          if (!mensal[month][vendedor]) mensal[month][vendedor] = 0;

          if (
            (monthDate >= inicioDate && (!fimDate || monthDate <= fimDate)) ||
            daysWorked > 0 // Garante inclusão se houve dias trabalhados
          ) {
            metaAcumulada += proportionalMeta;

            const indiceMensal = (vendasVendedor / proportionalMeta) * 100;
            if (indiceMensal > 0) {
              indices.push({
                vendedor,
                month,
                indice: indiceMensal.toFixed(2),
              });
            }
          }
        });

        // Índice acumulado
        if (acumulado[vendedor] > 0) {
          const indiceAcumulado = (acumulado[vendedor] / metaAcumulada) * 100;
          if (indiceAcumulado > 0) {
            indices.push({
              vendedor,
              month: "Acumulado",
              indice: indiceAcumulado.toFixed(2),
            });
          }
        }
      }
    );

    // Retorna os índices filtrados pelo mês selecionado (se aplicável)
    if (selectedMonth) {
      return indices.filter((data) => data.month === selectedMonth);
    }

    return indices;
  };

  const calculateAccumulatedIndice = (selectedVendedor) => {
    const relevantData = indiceMensal.filter(
      (data) => data.vendedor === selectedVendedor
    );

    let accumulatedSum = 0;
    const accumulatedIndices = relevantData.map((data) => {
      accumulatedSum += parseFloat(data.indice);
      return {
        month: data.month,
        accumulatedIndice: accumulatedSum / (relevantData.indexOf(data) + 1),
      };
    });

    return accumulatedIndices;
  };

  const calculateDaysInMonth = () => {
    const today = new Date();
    const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);

    const totalDays = (endOfMonth - startOfMonth) / (1000 * 60 * 60 * 24) + 1; // Total de dias no mês
    const elapsedDays =
      Math.floor((today - startOfMonth) / (1000 * 60 * 60 * 24)) + 1; // Dias passados inteiros

    return { totalDays, elapsedDays };
  };

  const calculateProportionalMeta = (month) => {
    const today = new Date();
    const currentMonth = `${today.getFullYear()}-${String(
      today.getMonth() + 1
    ).padStart(2, "0")}`;

    if (month === currentMonth) {
      const { totalDays, elapsedDays } = calculateDaysInMonth();
      const fullMonthMeta = metasMensais[month] || 0;

      // Ajuste proporcional da meta com base em dias corridos
      const proportionalMeta = (fullMonthMeta * elapsedDays) / totalDays;

      // Log para depuração
      console.log(
        "[calculateProportionalMeta - Dias Corridos] Meta Proporcional:"
      );
      console.log(`Mês: ${month}`);
      console.log(
        `Total de Dias Corridos: ${totalDays}, Dias Passados: ${elapsedDays}`
      );
      console.log(
        `Meta Completa: ${fullMonthMeta}, Meta Proporcional: ${proportionalMeta}`
      );

      return proportionalMeta;
    }

    // Retorna a meta completa para meses passados ou futuros
    return metasMensais[month] || 0;
  };

  // Função para calcular os dados de pagamento
  const calculatePaymentData = () => {
    const paymentData = {};
    const monthlyPaymentData = {};

    sales.forEach((sale) => {
      const saleDate = new Date(sale.data);
      const yearMonth = `${saleDate.getUTCFullYear()}-${String(
        saleDate.getUTCMonth() + 1
      ).padStart(2, "0")}`;

      // Adiciona o filtro pelo mês selecionado
      if (!selectedMonth || yearMonth === selectedMonth) {
        // Acumula os valores totais
        if (sale.entrada > 0 && sale.formaPgtEntrada) {
          paymentData[sale.formaPgtEntrada] =
            (paymentData[sale.formaPgtEntrada] || 0) + sale.entrada;
        }
        if (sale.saldo > 0 && sale.formaPgtSaldo) {
          paymentData[sale.formaPgtSaldo] =
            (paymentData[sale.formaPgtSaldo] || 0) + sale.saldo;
        }

        // Acumula os valores mês a mês
        if (!monthlyPaymentData[yearMonth]) {
          monthlyPaymentData[yearMonth] = {};
        }
        if (sale.entrada > 0 && sale.formaPgtEntrada) {
          monthlyPaymentData[yearMonth][sale.formaPgtEntrada] =
            (monthlyPaymentData[yearMonth][sale.formaPgtEntrada] || 0) +
            sale.entrada;
        }
        if (sale.saldo > 0 && sale.formaPgtSaldo) {
          monthlyPaymentData[yearMonth][sale.formaPgtSaldo] =
            (monthlyPaymentData[yearMonth][sale.formaPgtSaldo] || 0) +
            sale.saldo;
        }
      }
    });

    // Calcula as porcentagens acumuladas
    const totalAccumulated = Object.values(paymentData).reduce(
      (sum, value) => sum + value,
      0
    );
    const accumulatedPercentages = Object.entries(paymentData).map(
      ([paymentType, total]) => ({
        paymentType,
        percentage: ((total / totalAccumulated) * 100).toFixed(2),
        total,
      })
    );

    // Organiza os valores mês a mês
    const monthlyArray = Object.entries(monthlyPaymentData).map(
      ([month, payments]) => ({
        month,
        payments: Object.entries(payments).map(([paymentType, total]) => ({
          paymentType,
          total,
        })),
      })
    );

    setPaymentData({
      accumulatedPercentages,
      monthlyArray,
    });
  };

  // Função para obter o início e o fim do mês atual
  const getCurrentMonthRange = () => {
    const now = new Date();
    const start = new Date(now.getUTCFullYear(), now.getUTCMonth(), 1);
    const end = new Date(now.getUTCFullYear(), now.getUTCMonth() + 1, 0);

    start.setUTCHours(0, 0, 0, 0);
    end.setUTCHours(23, 59, 59, 999);

    return { start, end };
  };

  const calculateBoletoRecorrenteTotal = () => {
    const boletoPercentage =
      paymentData.accumulatedPercentages.find(
        (data) => data.paymentType.toLowerCase() === "boleto"
      )?.percentage || 0; // Pega a porcentagem de "boleto"

    const recorrentePercentage =
      paymentData.accumulatedPercentages.find(
        (data) => data.paymentType.toLowerCase() === "recorrente"
      )?.percentage || 0; // Pega a porcentagem de "recorrente"

    // Retorna a soma das porcentagens de boleto + recorrente
    return parseFloat(boletoPercentage) + parseFloat(recorrentePercentage);
  };

  const calculateBoletoRecorrenteSum = () => {
    const boleto =
      paymentData.accumulatedPercentages.find(
        (data) => data.paymentType.toLowerCase() === "boleto"
      )?.total || 0;

    const recorrente =
      paymentData.accumulatedPercentages.find(
        (data) => data.paymentType.toLowerCase() === "recorrente"
      )?.total || 0;

    return boleto + recorrente;
  };

  // Função para calcular os dados de origem
  const calculateOriginSums = () => {
    const originData = {};

    sales.forEach((sale) => {
      const saleDate = new Date(sale.data);
      const yearMonth = `${saleDate.getUTCFullYear()}-${String(
        saleDate.getUTCMonth() + 1
      ).padStart(2, "0")}`;

      // Filtra vendas pelo mês selecionado, ou acumula todas se `selectedMonth` não estiver definido
      if (!selectedMonth || yearMonth === selectedMonth) {
        if (!originData[sale.origem]) {
          originData[sale.origem] = 0;
        }
        originData[sale.origem] += sale.valor || 0; // Soma os valores por origem
      }
    });

    const originArray = Object.entries(originData)
      .map(([origin, total]) => ({
        origin,
        total,
      }))
      .sort((a, b) => b.total - a.total); // Ordena por valor decrescente

    setOriginSums(originArray);
  };

  useEffect(() => {
    if (sales.length > 0) {
      const indices = calculateIndiceMensal();
      setIndiceMensal(indices);
    }
  }, [sales]);

  useEffect(() => {
    if (sales.length > 0) {
      calculatePaymentData();
      const indices = calculateVendaMetaIndices();
      setVendaMetaIndices(indices);
    }
  }, [sales, selectedMonth]);

  // Lógica para calcular o acumulado de vendas por origem
  // useEffect para calcular o gráfico de origem
  useEffect(() => {
    calculateOriginSums();
  }, [sales, selectedMonth]); // Atualiza sempre que `sales` ou `selected

  useEffect(() => {
    const fetchSales = async () => {
      try {
        if (!currentUser) {
          console.error("Usuário não autenticado");
          setLoading(false);
          return;
        }

        const querySnapshot = await getDocs(collection(db, "sales"));
        const salesData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Ordena os dados por data (mais antiga primeiro)
        const sortedSales = salesData.sort((a, b) => {
          const dateA = new Date(a.data);
          const dateB = new Date(b.data);
          return dateA - dateB;
        });

        // Filtra as vendas do mês atual
        const { start, end } = getCurrentMonthRange();
        const filteredByMonth = sortedSales.filter((sale) => {
          const saleDate = new Date(sale.data);
          const saleDateUTC = new Date(
            saleDate.getUTCFullYear(),
            saleDate.getUTCMonth(),
            saleDate.getUTCDate()
          );

          const startUTC = new Date(
            start.getUTCFullYear(),
            start.getUTCMonth(),
            start.getUTCDate()
          );

          const endUTC = new Date(
            end.getUTCFullYear(),
            end.getUTCMonth(),
            end.getUTCDate()
          );

          return saleDateUTC >= startUTC && saleDateUTC <= endUTC;
        });

        setSales(sortedSales);
        setFilteredSales(filteredByMonth); // Inicialmente exibe apenas as vendas do mês atual
        setMonthlySums(calculateMonthlySums(sortedSales, selectedVendedor));
      } catch (error) {
        console.error("Erro ao buscar dados:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchSales();
  }, [currentUser, selectedVendedor]); // Reexecuta sempre que `selectedVendedor` mudar

  // Atualiza os dados de pagamento ao carregar as vendas ou alterar o mês
  useEffect(() => {
    if (sales.length > 0) {
      calculatePaymentData();
      const boletoRecorrenteSum = calculateBoletoRecorrenteSum();
    }
  }, [sales, selectedMonth]);

  // Função para calcular os dados por tipo de procedimento
  const calculateProcedureSums = () => {
    const procedureData = {};

    sales.forEach((sale) => {
      const saleDate = new Date(sale.data);
      const yearMonth = `${saleDate.getUTCFullYear()}-${String(
        saleDate.getUTCMonth() + 1
      ).padStart(2, "0")}`;

      // Filtra vendas pelo mês selecionado, ou acumula todas se `selectedProcedureMonth` não estiver definido
      if (!selectedProcedureMonth || yearMonth === selectedProcedureMonth) {
        if (!procedureData[sale.procedimento]) {
          procedureData[sale.procedimento] = 0;
        }
        procedureData[sale.procedimento] += sale.valor || 0;
        // Soma os valores por tipo de procedimento
      }
    });

    const procedureArray = Object.entries(procedureData)
      .map(([procedure, total]) => ({
        procedure,
        total,
      }))
      .sort((a, b) => b.total - a.total); // Ordena por valor decrescente

    setProcedureSums(procedureArray);
  };

  // useEffect para calcular o gráfico por tipo de procedimento
  useEffect(() => {
    calculateProcedureSums();
  }, [sales, selectedProcedureMonth]); // Atualiza sempre que `sales` ou `selectedProcedureMonth` mudar

  const calculateDiscountData = () => {
    const monthlyDiscounts = {};
    const cumulativeDiscounts = [];
    let cumulativeTotalValor = 0;
    let cumulativeTotalValorCheio = 0;

    sales.forEach((sale) => {
      const saleDate = new Date(sale.data);
      const yearMonth = `${saleDate.getUTCFullYear()}-${String(
        saleDate.getUTCMonth() + 1
      ).padStart(2, "0")}`;

      // Considera apenas vendas válidas e aplica o filtro de vendedora (se houver)
      if (
        (!selectedDiscountVendor || sale.vendedor === selectedDiscountVendor) &&
        sale.valorCheio > 0
      ) {
        if (!monthlyDiscounts[yearMonth]) {
          monthlyDiscounts[yearMonth] = { totalValor: 0, totalValorCheio: 0 };
        }

        // Soma os valores e os valores cheios
        monthlyDiscounts[yearMonth].totalValor += sale.valor;
        monthlyDiscounts[yearMonth].totalValorCheio += sale.valorCheio;
      }
    });

    const discountArray = Object.entries(monthlyDiscounts)
      .map(([month, { totalValor, totalValorCheio }]) => {
        const monthlyAverageDiscount = 1 - totalValor / totalValorCheio;

        // Atualiza os valores cumulativos
        cumulativeTotalValor += totalValor;
        cumulativeTotalValorCheio += totalValorCheio;
        const cumulativeAverageDiscount =
          1 - cumulativeTotalValor / cumulativeTotalValorCheio;

        return {
          month,
          monthlyAverageDiscount,
          cumulativeAverageDiscount,
        };
      })
      .sort((a, b) => new Date(a.month) - new Date(b.month));

    setDiscountData(discountArray);
  };

  useEffect(() => {
    if (sales.length > 0) {
      calculateDiscountData();
    }
  }, [sales, selectedDiscountVendor]);

  const calculateMonthlySums = (salesData, vendedor) => {
    const monthlyData = {};
    salesData.forEach((sale) => {
      if (!vendedor || sale.vendedor === vendedor) {
        const saleDate = new Date(sale.data);
        const yearMonth = `${saleDate.getUTCFullYear()}-${String(
          saleDate.getUTCMonth() + 1
        ).padStart(2, "0")}`;
        if (!monthlyData[yearMonth]) {
          monthlyData[yearMonth] = 0;
        }
        monthlyData[yearMonth] += sale.valor || 0;
      }
    });

    const monthlyArray = Object.entries(monthlyData)
      .map(([month, total]) => ({
        month,
        total,
      }))
      .sort((a, b) => new Date(a.month) - new Date(b.month));

    return monthlyArray;
  };

  // Manipula mudanças nos filtros
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const handleToggleChart = (chartId) => {
    setActiveChart((prev) => (prev === chartId ? null : chartId)); // Alterna o gráfico ativo
  };
  // Aplica os filtros
  const applyFilters = () => {
    if (
      filters.dataInicio &&
      filters.dataFim &&
      new Date(filters.dataInicio) > new Date(filters.dataFim)
    ) {
      alert("A data de início não pode ser maior que a data de fim.");
      return;
    }
    const filtered = sales.filter((sale) => {
      const nomeMatch = sale.nome
        .toLowerCase()
        .includes(filters.nome.toLowerCase());
      const dataMatch =
        (!filters.dataInicio ||
          new Date(sale.data) >= new Date(filters.dataInicio)) &&
        (!filters.dataFim || new Date(sale.data) <= new Date(filters.dataFim));
      const contratoMatch =
        !filters.contratoDigital ||
        sale.contratoDigital === filters.contratoDigital;
      const omerMatch =
        !filters.termoDigitalOmer ||
        sale.termoDigitalOmer === filters.termoDigitalOmer;
      const depilacaoMatch =
        !filters.termoDigitalDepilacao ||
        sale.termoDigitalDepilacao === filters.termoDigitalDepilacao;
      const vendedorMatch =
        !filters.vendedor ||
        sale.vendedor.toLowerCase().includes(filters.vendedor.toLowerCase());

      return (
        nomeMatch &&
        dataMatch &&
        contratoMatch &&
        omerMatch &&
        depilacaoMatch &&
        vendedorMatch
      );
    });

    setFilteredSales(filtered);
  };

  // Reseta os filtros
  const resetFilters = () => {
    setFilters({
      nome: "",
      dataInicio: "",
      dataFim: "",
      contratoDigital: "",
      termoDigitalOmer: "",
      termoDigitalDepilacao: "",
      vendedor: "",
    });

    // Restaura os dados do mês atual ao limpar os filtros
    const { start, end } = getCurrentMonthRange();
    const filteredByMonth = sales.filter((sale) => {
      const saleDate = new Date(sale.data);
      return saleDate >= start && saleDate <= end;
    });
    setFilteredSales(filteredByMonth);
  };

  // Calcula o total de vendas no mês atual
  const getTotalSalesValue = () => {
    return filteredSales
      .reduce((total, sale) => total + sale.valor, 0)
      .toFixed(2);
  };

  if (loading) {
    return <div className="loading">Carregando...</div>;
  }

  if (!sales.length) {
    return <div className="no-data">Nenhuma venda encontrada.</div>;
  }

  const chartData = {
    labels: monthlySums.map((data) => data.month),
    datasets: [
      {
        label: "Total de Vendas (R$)",
        data: monthlySums.map((data) => data.total),
        backgroundColor: "rgba(38, 182, 180, 0.8)",
        borderColor: "rgba(38, 182, 180, 1)",
        borderWidth: 1,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Total de Vendas por Mês (R$)",
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: "Valor (R$)",
        },
      },
      x: {
        title: {
          display: true,
          text: "Mês/Ano",
        },
      },
    },
  };

  const originChartData = {
    labels: originSums.map((data) => data.origin), // Nomes das origens
    datasets: [
      {
        label: "Total de Vendas (R$) por Origem",
        data: originSums.map((data) => data.total), // Valores acumulados
        backgroundColor: "rgba(75, 192, 192, 0.8)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
    ],
  };

  const originChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: `Total de Vendas por Origem (${
          selectedMonth || "Mês Não Selecionado"
        })`,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: "Valor (R$)",
        },
      },
      x: {
        title: {
          display: true,
          text: "Origem",
        },
      },
    },
  };

  // Dados do gráfico de tipo de procedimento
  const procedureChartData = {
    labels: procedureSums.map((data) => data.procedure), // Nomes dos tipos de procedimento
    datasets: [
      {
        label: "Total de Vendas (R$) por Tipo de Procedimento",
        data: procedureSums.map((data) => data.total), // Valores acumulados
        backgroundColor: "rgba(153, 102, 255, 0.8)",
        borderColor: "rgba(153, 102, 255, 1)",
        borderWidth: 1,
      },
    ],
  };

  const procedureChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: `Total de Vendas por Tipo de Procedimento (${
          selectedProcedureMonth || "Acumulado"
        })`,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: "Valor (R$)",
        },
      },
      x: {
        title: {
          display: true,
          text: "Tipo de Procedimento",
        },
      },
    },
  };

  const discountChartData = {
    labels: discountData.map((data) => data.month),
    datasets: [
      {
        label: "Desconto Médio Mensal (%)",
        data: discountData.map((data) =>
          (data.monthlyAverageDiscount * 100).toFixed(2)
        ),
        backgroundColor: "rgba(75, 192, 192, 0.5)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
        type: "bar", // Barras para desconto mensal
      },
      {
        label: "Desconto Médio Acumulado (%)",
        data: discountData.map((data) =>
          (data.cumulativeAverageDiscount * 100).toFixed(2)
        ),
        backgroundColor: "rgba(153, 102, 255, 0.2)",
        borderColor: "rgba(153, 102, 255, 1)",
        borderWidth: 2,
        type: "line", // Linha para desconto acumulado
      },
    ],
  };

  const discountChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Desconto Médio Mensal e Acumulado (%)",
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: "Desconto (%)",
        },
      },
      x: {
        title: {
          display: true,
          text: "Mês/Ano",
        },
      },
    },
  };

  // Dados para o gráfico
  const boletoRecorrenteTotal = calculateBoletoRecorrenteTotal();

  const paymentChartData = {
    labels: paymentData.accumulatedPercentages.map((data) => data.paymentType),
    datasets: [
      {
        label: "Porcentagem Acumulada de Pagamentos (%)",
        data: paymentData.accumulatedPercentages.map((data) =>
          parseFloat(data.percentage)
        ),
        backgroundColor: "rgba(54, 162, 235, 0.5)",
        borderColor: "rgba(54, 162, 235, 1)",
        borderWidth: 1,
      },
      {
        label: "Soma de Boleto + Recorrente (Linha)",
        data: Array(paymentData.accumulatedPercentages.length).fill(
          calculateBoletoRecorrenteTotal()
        ),
        type: "line",
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 2,
        pointRadius: 5, // Exibe pontos visíveis
        pointHoverRadius: 8, // Expande o ponto ao passar o mouse
        pointBackgroundColor: "rgba(255, 99, 132, 1)", // Cor do ponto
        tension: 0.4, // Linha suavizada
      },
    ],
  };

  const monthlyPaymentChartData = {
    labels: paymentData.monthlyArray.map((data) => data.month),
    datasets: paymentData.monthlyArray[0]?.payments.map((_, index) => ({
      label: paymentData.monthlyArray[0].payments[index].paymentType,
      data: paymentData.monthlyArray.map(
        (monthData) =>
          monthData.payments.find(
            (payment) =>
              payment.paymentType ===
              paymentData.monthlyArray[0].payments[index].paymentType
          )?.total || 0
      ),
      backgroundColor: `rgba(${index * 50}, 99, 132, 0.5)`,
      borderColor: `rgba(${index * 50}, 99, 132, 1)`,
      borderWidth: 1,
    })),
  };
  const paymentChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Porcentagem Acumulada e Soma de Boleto + Recorrente",
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: "Valores (%)",
        },
      },
      x: {
        title: {
          display: true,
          text: "Tipos de Pagamento",
        },
      },
    },
  };

  const getSortedData = () => {
    if (selectedMonth) {
      // Ordena os dados mensais, excluindo "Loja"
      const filteredData = indiceMensal.filter(
        (data) => data.month === selectedMonth && data.vendedor !== "Loja"
      );
      return filteredData.sort(
        (a, b) => parseFloat(b.indice) - parseFloat(a.indice)
      );
    } else {
      // Ordena os dados acumulados, excluindo "Loja"
      const acumuladoData = [
        "Ana Claudia",
        "Camila",
        "Carolina",
        "Enny",
        "Isabella",
        "Rayssa",
        "Thaís",
      ].map((vendedora) => ({
        vendedor: vendedora,
        indice: (
          indiceMensal
            .filter((data) => data.vendedor === vendedora)
            .reduce((acc, curr) => acc + parseFloat(curr.indice), 0) /
            indiceMensal.filter((data) => data.vendedor === vendedora).length ||
          0
        ).toFixed(1), // Limita para 1 casa decimal
      }));

      return acumuladoData.sort(
        (a, b) => parseFloat(b.indice) - parseFloat(a.indice)
      );
    }
  };

  const sortedData = getSortedData();

  const vendaMetaChartData = {
    labels: sortedData.map((data) => data.vendedor),
    datasets: [
      {
        label: selectedMonth
          ? `Índice Mensal - ${selectedMonth}`
          : "Índice Acumulado",
        data: sortedData.map((data) => parseFloat(data.indice)),
        backgroundColor: "rgba(75, 192, 192, 0.8)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
    ],
  };

  const vendaMetaChartOptions = {
    responsive: true,
    plugins: {
      legend: { position: "top" },
      title: {
        display: true,
        text: `Índice Venda/Meta - ${
          selectedMonth ? `Mensal (${selectedMonth})` : "Acumulado"
        }`,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        max: 150,
        title: { display: true, text: "Índice (%)" },
      },
      x: {
        title: { display: true, text: "Funcionárias" },
      },
    },
  };

  const accumulatedData = calculateAccumulatedIndice(selectedIndiceVendedor);

  const indiceMensalChartData = {
    labels: indiceMensal
      .filter((data) => data.vendedor === selectedIndiceVendedor)
      .map((data) => data.month),
    datasets: [
      {
        label: `Índice Venda/Meta (${selectedIndiceVendedor})`,
        data: indiceMensal
          .filter((data) => data.vendedor === selectedIndiceVendedor)
          .map((data) => parseFloat(data.indice)),
        backgroundColor: "rgba(75, 192, 192, 0.8)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
      {
        label: "Índice Acumulado",
        data: accumulatedData.map((data) => data.accumulatedIndice.toFixed(2)),
        type: "line",
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 2,
        pointRadius: 5,
        tension: 0.4,
      },
    ],
  };

  const indiceMensalChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: `Índice Venda/Meta Mensal (${selectedIndiceVendedor})`,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: "Índice (%)",
        },
      },
      x: {
        title: {
          display: true,
          text: "Mês/Ano",
        },
      },
    },
  };

  const calculateRemainingDays = () => {
    const today = new Date();
    const lastDayOfMonth = new Date(
      today.getFullYear(),
      today.getMonth() + 1,
      0
    );

    let remainingDays = 0;

    for (
      let date = new Date(today);
      date <= lastDayOfMonth;
      date.setDate(date.getDate() + 1)
    ) {
      const dayOfWeek = date.getDay();
      if (dayOfWeek >= 1 && dayOfWeek <= 6) {
        // Contabiliza apenas de segunda (1) a sábado (6)
        remainingDays++;
      }
    }

    return remainingDays;
  };

  const calculateDailyTarget = () => {
    const remainingDays = calculateRemainingDays();
    const remainingTarget = metasMensais["2024-11"] - getTotalSalesValue();
    return (remainingTarget / remainingDays).toFixed(2);
  };

  // Gráfico de Sessões por Serviço
  const serviceChartData = {
    labels: serviceSessions ? Object.keys(serviceSessions) : [],
    datasets: [
      {
        label: "Número de Sessões por Serviço",
        data: serviceSessions ? Object.values(serviceSessions) : [],
        backgroundColor: "rgba(54, 162, 235, 0.5)",
        borderColor: "rgba(54, 162, 235, 1)",
        borderWidth: 1,
      },
    ],
  };

  const serviceChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Gráfico de Sessões por Serviço",
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: "Número de Sessões",
        },
      },
      x: {
        title: {
          display: true,
          text: "Serviços",
        },
      },
    },
  };

  // Dados para o gráfico
  const salesPerDayChartData = {
    labels: Object.keys(salesPerDay),
    datasets: [
      {
        label: "Vendas por Dia Trabalhado",
        data: Object.values(salesPerDay),
        backgroundColor: "rgba(75, 192, 192, 0.8)", // Cor de fundo das barras
        borderColor: "rgba(75, 192, 192, 1)", // Cor da borda das barras
        borderWidth: 1, // Largura da borda
      },
    ],
  };

  const salesPerDayChartOptions = {
    responsive: true,
    plugins: {
      legend: { position: "top" },
      title: {
        display: true,
        text: "Vendas por Dia Trabalhado por Vendedora",
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        title: { display: true, text: "Valor (R$)" },
      },
      x: {
        title: { display: true, text: "Vendedora" },
      },
    },
  };

  // Dados para o gráfico de barras
  const accumulatedSalesChartData = {
    labels: accumulatedSales.map((data) => data.vendedor),
    datasets: [
      {
        label: "Valor Acumulado de Vendas (R$)",
        data: accumulatedSales.map((data) => data.total),
        backgroundColor: "rgba(255, 99, 132, 0.8)", // Cor de fundo das barras - personalize se desejar
        borderColor: "rgba(255, 99, 132, 1)", // Cor da borda das barras
        borderWidth: 1, // Largura da borda
      },
    ],
  };

  const accumulatedSalesChartOptions = {
    responsive: true,
    plugins: {
      legend: { position: "top" },
      title: {
        display: true,
        text: "Valor Acumulado de Vendas por Funcionário",
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        title: { display: true, text: "Valor (R$)" },
      },
      x: {
        title: { display: true, text: "Funcionário" },
      },
    },
  };

  return (
    <section id="consulta-venda">
      <div className="adicionar-venda-container">
        {" "}
        <h2>Gestão de Vendas</h2>
        {/* Botão para exibir o modal */}
        <button
          className="adicionar-venda-btn"
          onClick={() => setShowNewSaleModal(true)}
        >
          Adicionar Nova Venda
        </button>
      </div>

      {/* Modal para Nova Venda */}
      {showNewSaleModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h3>Adicionar Nova Venda</h3>
            <form className="nova-venda-form" onSubmit={handleAddSale}>
              <label>ID da Venda:</label>
              <input
                type="number"
                value={newSale.ID}
                onChange={(e) => setNewSale({ ...newSale, ID: e.target.value })}
                required
              />

              <label>CPF do Cliente:</label>
              <input
                type="text"
                value={newSale.cpf}
                onChange={(e) =>
                  setNewSale({ ...newSale, cpf: e.target.value })
                }
                onBlur={async () => {
                  if (!validateCPF(newSale.cpf)) {
                    alert("CPF inválido! Por favor, insira um CPF válido.");
                    return;
                  }

                  try {
                    const querySnapshot = await getDocs(
                      collection(db, "sales")
                    );
                    const matchingSales = querySnapshot.docs
                      .map((doc) => ({ id: doc.id, ...doc.data() }))
                      .filter((sale) => sale.cpf === formatCPF(newSale.cpf));

                    if (matchingSales.length > 0) {
                      const matchedSale = matchingSales[0];

                      const calculatedAge = calculateAge(
                        matchedSale.aniversario
                      );

                      setNewSale((prev) => ({
                        ...prev,
                        nome: matchedSale.nome || "",
                        aniversario: matchedSale.aniversario || "",
                        idade: calculatedAge || "", // Adiciona a idade calculada
                      }));

                      alert(
                        "Dados preenchidos automaticamente com base no CPF!"
                      );
                    } else {
                      alert("Nenhum registro encontrado para este CPF.");
                    }
                  } catch (error) {
                    console.error("Erro ao buscar dados por CPF:", error);
                    alert("Erro ao buscar dados. Por favor, tente novamente.");
                  }
                }}
                pattern="^\d{3}\.?\d{3}\.?\d{3}-?\d{2}$" // Regex para validação básica
                title="Insira um CPF válido no formato 123.456.789-10 ou 12345678910"
                required
              />

              <label>Nome do Cliente:</label>
              <input
                type="text"
                value={newSale.nome}
                onChange={(e) =>
                  setNewSale({ ...newSale, nome: e.target.value })
                }
                required
              />

              <label>Data da Venda:</label>
              <input
                type="date"
                value={newSale.data} // Usa diretamente o valor no formato "YYYY-MM-DD"
                onChange={(e) => {
                  const selectedDate = e.target.value; // Captura o valor no formato "YYYY-MM-DD"
                  setNewSale({ ...newSale, data: selectedDate }); // Atualiza no estado como "YYYY-MM-DD"
                }}
                required
              />

              <label>Procedimento:</label>
              <select
                value={newSale.procedimento}
                onChange={(e) =>
                  setNewSale({ ...newSale, procedimento: e.target.value })
                }
                required
              >
                <option value="">Selecione um procedimento</option>
                <option value="Depilação">Depilação</option>
                <option value="Depilação + Ômer">Depilação + Ômer</option>
                <option value="Depilação + Ômer (grátis)">
                  Depilação + Ômer (grátis)
                </option>
                <option value="Ômer">Ômer</option>
                <option value="Ômer + Depilação (grátis)">
                  Ômer + Depilação (grátis)
                </option>
              </select>

              <label>Valor da Venda:</label>
              <input
                type="number"
                value={newSale.valor}
                onChange={(e) =>
                  setNewSale({ ...newSale, valor: parseFloat(e.target.value) })
                }
                required
              />
              <label>Preço Cheio:</label>
              <input
                type="number"
                value={newSale.valorCheio}
                onChange={(e) =>
                  setNewSale({
                    ...newSale,
                    valorCheio: parseFloat(e.target.value),
                  })
                }
                required
              />
              <label>Vendedor:</label>
              <select
                value={newSale.vendedor}
                onChange={(e) =>
                  setNewSale({ ...newSale, vendedor: e.target.value })
                }
                required
              >
                <option value="">Selecione o vendedor</option>
                <option value="Clara">Clara</option>
                <option value="Enny">Enny</option>
                <option value="Isabella">Isabella</option>
                <option value="Rayssa">Rayssa</option>
                <option value="Thaís">Thaís</option>
              </select>
              <label>Origem:</label>
              <select
                value={newSale.origem}
                onChange={(e) =>
                  setNewSale({ ...newSale, origem: e.target.value })
                }
                required
              >
                <option value="">Selecione a origem</option>
                <option value="Tráfego Pago">Tráfego Pago</option>
                <option value="Passante">Passante</option>
                <option value="Instagram">Instagram</option>
                <option value="Passante">Passante</option>
                <option value="Revenda">Revenda</option>
                <option value="Panfletos">Panfletos</option>
                <option value="Google">Google</option>
                <option value="Indique">Indique</option>
                <option value="Família e Amigos">Família e Amigos</option>
              </select>

              <label>Entrada:</label>
              <input
                type="number"
                value={newSale.entrada}
                onChange={(e) =>
                  setNewSale({
                    ...newSale,
                    entrada: parseFloat(e.target.value),
                  })
                }
              />

              <label>Forma de Pagamento da Entrada:</label>
              <select
                value={newSale.formaPgtEntrada}
                onChange={(e) =>
                  setNewSale({ ...newSale, formaPgtEntrada: e.target.value })
                }
                required
              >
                <option value="">Selecione a forma de pagamento</option>
                <option value="Não Aplicável">Não Aplicável</option>
                <option value="Pix">Pix</option>
                <option value="Dinheiro">Dinheiro</option>
                <option value="Débito">Débito</option>
                <option value="Crédito">Crédito</option>
              </select>

              <label>Forma de Pagamento do Saldo:</label>
              <select
                value={newSale.formaPgtSaldo}
                onChange={(e) =>
                  setNewSale({ ...newSale, formaPgtSaldo: e.target.value })
                }
                required
              >
                <option value="">Selecione a forma de pagamento</option>
                <option value="Pix">Pix</option>
                <option value="Dinheiro">Dinheiro</option>
                <option value="Débito">Débito</option>
                <option value="Crédito">Crédito</option>
                <option value="Recorrente">Recorrente</option>
                <option value="Boleto">Boleto</option>
              </select>

              <label>Parcelas:</label>
              <select
                value={newSale.parcelas}
                onChange={(e) =>
                  setNewSale({ ...newSale, parcelas: parseInt(e.target.value) })
                }
                required
              >
                <option value="">Selecione o número de parcelas</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
                <option value="13">13</option>
                <option value="14">14</option>
                <option value="15">15</option>
                <option value="16">16</option>
                <option value="17">17</option>
                <option value="18">18</option>
              </select>

              <label>Data de Aniversário:</label>
              <input
                type="date"
                value={newSale.aniversario}
                onChange={(e) => handleBirthdayChange(e.target.value)}
              />

              {[...Array(visibleServices)].map((_, index) => (
                <div key={index}>
                  <label>Serviço {index + 1}:</label>
                  <div>
                    <label>Número de Sessões:</label>
                    <input
                      type="number"
                      min="1"
                      max="10"
                      value={
                        newSale[`servico${index + 1}`]?.split(" ")[0] || ""
                      }
                      onChange={(e) =>
                        handleServiceChange(index, "sessions", e.target.value)
                      }
                    />
                  </div>
                  <div>
                    <label>Tratamento:</label>
                    <select
                      value={
                        newSale[`servico${index + 1}`]
                          ?.split(" ")
                          .slice(2)
                          .join(" ") || ""
                      }
                      onChange={(e) =>
                        handleServiceChange(index, "treatment", e.target.value)
                      }
                    >
                      <option value="" disabled>
                        Selecione um tratamento
                      </option>
                      {treatments.map((treatment, idx) => (
                        <option key={idx} value={treatment}>
                          {treatment}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <strong>{newSale[`servico${index + 1}`] || ""}</strong>
                  </div>
                </div>
              ))}

              {visibleServices < 6 && (
                <button
                  type="button"
                  onClick={() => setVisibleServices(visibleServices + 1)}
                >
                  Adicionar Serviço
                </button>
              )}
              <label>Contrato Digital:</label>
              <select
                value={newSale.contratoDigital}
                onChange={(e) =>
                  setNewSale({ ...newSale, contratoDigital: e.target.value })
                }
              >
                <option value="">Selecione</option>
                <option value="Sim">Assinado</option>
                <option value="Pendente">Pendente Assinatura</option>
                <option value="Não Aplicável">Não Aplicável</option>
              </select>

              <label>Termo Digital Ômer:</label>
              <select
                value={newSale.termoDigitalOmer}
                onChange={(e) =>
                  setNewSale({ ...newSale, termoDigitalOmer: e.target.value })
                }
              >
                <option value="">Selecione</option>
                <option value="Sim">Assinado</option>
                <option value="Pendente">Pendente Assinatura</option>
                <option value="Não Aplicável">Não Aplicável</option>
              </select>

              <label>Termo Digital Depilação:</label>
              <select
                value={newSale.termoDigitalDepilacao}
                onChange={(e) =>
                  setNewSale({
                    ...newSale,
                    termoDigitalDepilacao: e.target.value,
                  })
                }
              >
                <option value="">Selecione</option>
                <option value="Sim">Assinado</option>
                <option value="Pendente">Pendente Assinatura</option>
                <option value="Não Aplicável">Não Aplicável</option>
              </select>

              <div className="modal-actions">
                <button type="submit" className="salvar-btn">
                  Salvar
                </button>
                <button
                  type="button"
                  className="cancelar-btn"
                  onClick={() => setShowNewSaleModal(false)}
                >
                  Cancelar
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* Div do Total de Vendas */}
      <div className="contador-anotacoes">
        <div className="contador-box">
          <p className="contador-titulo">Total de Vendas no Mês: </p>
          <p className="contador-numero">R$ {getTotalSalesValue()}</p>
        </div>
        <div className="contador-box">
          <p className="contador-titulo">Venda Diária Necessária para Meta: </p>
          <p className="contador-numero">R$ {calculateDailyTarget()}</p>
        </div>
      </div>
      <div className="contador-anotacoes">
        {currentUserOption?.label !== "Dione" && (
          <>
            <div className="contador-box">
              {" "}
              {/* Novo contador */}
              <p className="contador-titulo">
                Total de Vendas de Todos os Tempos:
              </p>
              <p className="contador-numero">
                R$ {totalSalesAllTime.toFixed(2)}
              </p>
            </div>
            <div className="contador-box">
              {" "}
              {/* Novo contador */}
              <p className="contador-titulo">Valor de Vendas Canceladas:</p>
              <p className="contador-numero">
                R$ {totalCancelledSales.toFixed(2)} (
                {((totalCancelledSales / totalSalesAllTime) * 100).toFixed(2)}%)
              </p>
            </div>
            <div className="contador-box">
              {" "}
              {/* Novo contador */}
              <p className="contador-titulo">Saldo Final de Vendas:</p>
              <p className="contador-numero">
                R${" "}
                {totalSalesAllTime.toFixed(2) - totalCancelledSales.toFixed(2)}
              </p>
            </div>
          </>
        )}
      </div>
      <div className="contador-anotacoes">
        {Object.entries(pendingCounts).map(([key, value]) => {
          let titulo = "";
          switch (key) {
            case "contratoDigital":
              titulo = "Contrato Pendentes";
              break;
            case "termoDigitalOmer":
              titulo = "Termo Ômer Pendentes";
              break;
            case "termoDigitalDepilacao":
              titulo = "Termo Depilação Pendentes";
              break;
            default:
              titulo = key; // Valor padrão caso a chave não corresponda a nenhum caso
          }

          return (
            <div key={key} className="contador-box">
              <p className="contador-titulo">{titulo}:</p>
              <p className="contador-numero">
                {value.pending} ({value.percentage.toFixed(2)}%)
              </p>
            </div>
          );
        })}
      </div>
      {/* Botão para exibir o gráfico */}
      <h3>Gráficos</h3>
      <div className="chart-buttons">
        <button onClick={() => handleToggleChart("salesChart")}>
          {activeChart === "salesChart"
            ? "Esconder Gráfico de Vendas"
            : "Análise Venda Acumulada"}
        </button>
        <button onClick={() => handleToggleChart("originChart")}>
          {activeChart === "originChart"
            ? "Esconder Gráfico de Origem de Vendas"
            : "Análise por Origem de Vendas"}
        </button>
        <button onClick={() => handleToggleChart("procedureChart")}>
          {activeChart === "procedureChart"
            ? "Esconder Gráfico por Procedimento"
            : "Análise por Equipamento"}
        </button>
        <button onClick={() => handleToggleChart("discountChart")}>
          {activeChart === "discountChart"
            ? "Esconder Gráfico de Descontos"
            : "Análise Descontos Concedidos"}
        </button>
        <button onClick={() => handleToggleChart("paymentChart")}>
          {activeChart === "paymentChart"
            ? "Esconder Gráfico de Pagamentos"
            : "Análise por Tipos de Pagamento"}
        </button>
        <button onClick={() => handleToggleChart("salesPerDayChart")}>
          {activeChart === "salesPerDayChart"
            ? "Esconder Gráfico Vendas/Dia"
            : "Vendas por Dia Trabalhado"}
        </button>
        <button onClick={() => handleToggleChart("accumulatedSalesChart")}>
          {activeChart === "accumulatedSalesChart"
            ? "Esconder Gráfico Venda por Funcionário Acumulado"
            : "Mostrar Gráfico Venda por Funcionário Acumulado"}
        </button>
        {currentUserOption?.label !== "Dione" && (
          <>
            <button onClick={downloadExcel}>Baixar Registro Vendas</button>
          </>
        )}
        <button onClick={() => handleToggleChart("vendaMetaChart")}>
          {activeChart === "vendaMetaChart"
            ? "Esconder Gráfico Venda/Meta"
            : "Índice Venda/Meta - Análise Coletiva"}
        </button>
        <button onClick={() => handleToggleChart("indiceMensalChart")}>
          {activeChart === "indiceMensalChart"
            ? "Esconder Gráfico Índice Mensal"
            : "Índice Venda/Meta - Individual"}
        </button>
        <button
          onClick={() => {
            calculateSessionsByService(sales); // Calcula as sessões
            handleToggleChart("serviceChart"); // Exibe o gráfico
          }}
        >
          {activeChart === "serviceChart"
            ? "Esconder Gráfico de Sessões"
            : "Gerar Gráfico de Sessões por Serviço"}
        </button>
        {/* Adicione mais botões para outros gráficos aqui */}
      </div>
      {/* Gráfico */}
      {activeChart === "salesChart" && (
        <div className="chart-container">
          {/* Filtro por Vendedor */}
          <div className="filtro-vendedor">
            <label htmlFor="vendedor">Filtrar por Vendedor:</label>
            <select
              id="vendedor"
              value={selectedVendedor}
              onChange={(e) => setSelectedVendedor(e.target.value)}
            >
              <option value="">Todos os Vendedores</option>
              <option value="Ana Claudia">Ana Claudia</option>
              <option value="Camila">Camila</option>
              <option value="Carolina">Carolina</option>
              <option value="Clara">Clara</option>
              <option value="Enny">Enny</option>
              <option value="Isabella">Isabella</option>
              <option value="Rayssa">Rayssa</option>
              <option value="Thaís">Thaís</option>
            </select>
          </div>
          <Bar
            ref={salesChartRef}
            data={{
              labels: monthlySums.map((data) => data.month),
              datasets: [
                {
                  label: "Total de Vendas (R$)",
                  data: monthlySums.map((data) => data.total),
                  backgroundColor: "rgba(38, 182, 180, 0.8)",
                  borderColor: "rgba(38, 182, 180, 1)",
                  borderWidth: 1,
                },
              ],
            }}
            options={{
              responsive: true,
              plugins: {
                legend: {
                  position: "top",
                },
                title: {
                  display: true,
                  text: "Total de Vendas por Mês (R$)",
                },
              },
              scales: {
                y: {
                  beginAtZero: true,
                  title: {
                    display: true,
                    text: "Valor (R$)",
                  },
                },
                x: {
                  title: {
                    display: true,
                    text: "Mês/Ano",
                  },
                },
              },
            }}
          />
          {currentUserOption?.label !== "Dione" && (
            <button
              onClick={() =>
                handleDownloadChart(salesChartRef, "Gráfico de Vendas")
              }
            >
              Baixar Gráfico
            </button>
          )}

          {/* Botão de download */}
        </div>
      )}
      {/* Renderiza o Gráfico de Vendas/Dia */}
      {activeChart === "salesPerDayChart" && (
        <div className="chart-container">
          <Bar
            ref={salesPerDayChartRef}
            data={salesPerDayChartData}
            options={salesPerDayChartOptions}
          />
          {currentUserOption?.label !== "Dione" && (
            <button
              onClick={() =>
                handleDownloadChart(
                  salesPerDayChartRef,
                  "Gráfico de Vendas por Dia"
                )
              }
            >
              Baixar Gráfico
            </button>
          )}
        </div>
      )}
      {/* Renderiza o Gráfico de Origem de Vendas */}
      {activeChart === "originChart" && (
        <div className="origin-chart-section">
          {/* Filtro de Mês */}
          <div className="filter-month">
            <label htmlFor="month">Selecionar Mês:</label>
            <input
              type="month"
              id="month"
              value={selectedMonth}
              onChange={(e) => setSelectedMonth(e.target.value)}
            />
          </div>

          {/* Gráfico */}
          {originSums.length > 0 && (
            <div className="chart-container">
              <h3>Gráfico de Origem de Vendas</h3>
              <Bar
                ref={originChartRef}
                data={originChartData}
                options={originChartOptions}
              />
              {currentUserOption?.label !== "Dione" && (
                <button
                  onClick={() =>
                    handleDownloadChart(
                      originChartRef,
                      "Gráfico de Origem de Vendas"
                    )
                  }
                >
                  Baixar Gráfico
                </button>
              )}
            </div>
          )}
        </div>
      )}

      {/* Renderiza o Gráfico por Tipo de Procedimento */}
      {activeChart === "procedureChart" && (
        <div className="procedure-chart-section">
          {/* Filtro de Mês */}
          <div className="filter-month">
            <label htmlFor="procedureMonth">Selecionar Mês:</label>
            <input
              type="month"
              id="procedureMonth"
              value={selectedProcedureMonth}
              onChange={(e) => setSelectedProcedureMonth(e.target.value)}
            />
          </div>

          {/* Verificação antes de exibir o gráfico */}
          {procedureSums.length > 0 ? (
            <div className="chart-container">
              <h3>Gráfico por Tipo de Procedimento</h3>
              <Bar
                ref={procedureChartRef}
                data={procedureChartData}
                options={procedureChartOptions}
              />
              {currentUserOption?.label !== "Dione" && (
                <button
                  onClick={() =>
                    handleDownloadChart(
                      procedureChartRef,
                      "Gráfico por Tipo de Procedimento"
                    )
                  }
                >
                  Baixar Gráfico
                </button>
              )}
            </div>
          ) : (
            <p>Sem dados disponíveis para o mês selecionado.</p>
          )}
        </div>
      )}

      {activeChart === "accumulatedSalesChart" && (
        <div className="chart-container">
          {" "}
          {/* Use a mesma classe CSS para consistência */}
          <Bar
            ref={accumulatedSalesChartRef}
            data={accumulatedSalesChartData}
            options={accumulatedSalesChartOptions}
          />
          {currentUserOption?.label !== "Dione" && (
            <button
              onClick={() =>
                handleDownloadChart(
                  accumulatedSalesChartRef,
                  "Gráfico de Vendas Acumuladas"
                )
              }
            >
              Baixar Gráfico
            </button>
          )}
        </div>
      )}

      {activeChart === "discountChart" && (
        <div className="discount-chart-section">
          {/* Filtro por Vendedora */}
          <div className="filtro-vendedora">
            <label htmlFor="vendedor-desconto">Filtrar por Vendedora:</label>
            <select
              id="vendedor-desconto"
              value={selectedDiscountVendor}
              onChange={(e) => setSelectedDiscountVendor(e.target.value)}
            >
              <option value="">Todas as Vendedoras</option>
              <option value="Ana Claudia">Ana Claudia</option>
              <option value="Camila">Camila</option>
              <option value="Carolina">Carolina</option>
              <option value="Clara">Clara</option>
              <option value="Enny">Enny</option>
              <option value="Isabella">Isabella</option>
              <option value="Rayssa">Rayssa</option>
              <option value="Thaís">Thaís</option>
            </select>
          </div>

          {/* Gráfico */}
          <div className="chart-container">
            <Bar
              ref={discountChartRef}
              data={discountChartData}
              options={discountChartOptions}
            />
            {currentUserOption?.label !== "Dione" && (
              <button
                onClick={() =>
                  handleDownloadChart(discountChartRef, "Gráfico de Desconto")
                }
              >
                Baixar Gráfico
              </button>
            )}
          </div>
        </div>
      )}
      {/* Gráfico de Pagamentos */}
      {activeChart === "paymentChart" && (
        <div className="payment-chart-section">
          <div className="filter-month">
            <label htmlFor="month">Selecionar Mês:</label>
            <input
              type="month"
              id="month"
              value={selectedMonth}
              onChange={(e) => setSelectedMonth(e.target.value)}
            />
          </div>
          <div className="chart-container">
            <h3>Porcentagem Acumulada de Pagamentos</h3>
            <Bar
              ref={paymentChartRef}
              data={paymentChartData}
              options={paymentChartOptions}
            />
            {currentUserOption?.label !== "Dione" && (
              <button
                onClick={() =>
                  handleDownloadChart(
                    paymentChartRef,
                    "Gráfico de Pagamentos Acumulados"
                  )
                }
              >
                Baixar Gráfico
              </button>
            )}
          </div>

          {selectedMonth && (
            <div className="chart-container">
              <h3>Valores Mensais por Tipo de Pagamento</h3>
              <Bar data={monthlyPaymentChartData} />
              {currentUserOption?.label !== "Dione" && (
                <button
                  onClick={() =>
                    handleDownloadChart(
                      paymentChartRef,
                      "Gráfico de Pagamentos Acumulados"
                    )
                  }
                >
                  Baixar Gráfico
                </button>
              )}
            </div>
          )}
        </div>
      )}
      {activeChart === "vendaMetaChart" && (
        <div className="chart-container">
          <div className="filter-month">
            <label htmlFor="vendaMetaMonth">Selecionar Mês:</label>
            <input
              type="month"
              id="vendaMetaMonth"
              value={selectedMonth}
              onChange={(e) => setSelectedMonth(e.target.value)}
            />
          </div>
          <Bar
            ref={vendaMetaChartRef}
            data={vendaMetaChartData}
            options={vendaMetaChartOptions}
          />
          {currentUserOption?.label !== "Dione" && (
            <button
              onClick={() =>
                handleDownloadChart(
                  vendaMetaChartRef,
                  "Gráfico de Índices de Metas/Vendas Coletivas"
                )
              }
            >
              Baixar Gráfico
            </button>
          )}
        </div>
      )}
      {activeChart === "indiceMensalChart" && (
        <div className="indice-mensal-chart-section">
          {/* Filtro por Vendedor */}
          <div className="filtro-vendedor">
            <label htmlFor="vendedor-indice">Selecionar Vendedor:</label>
            <select
              id="vendedor-indice"
              value={selectedIndiceVendedor}
              onChange={(e) => setSelectedIndiceVendedor(e.target.value)}
            >
              <option value="Loja">Loja</option>
              <option value="Ana Claudia">Ana Claudia</option>
              <option value="Camila">Camila</option>
              <option value="Carolina">Carolina</option>
              <option value="Enny">Enny</option>
              <option value="Isabella">Isabella</option>
              <option value="Rayssa">Rayssa</option>
              <option value="Thaís">Thaís</option>
            </select>
          </div>

          {/* Renderiza o Gráfico */}
          <div className="chart-container">
            <Bar
              ref={indiceMensalChartRef}
              data={indiceMensalChartData}
              options={indiceMensalChartOptions}
            />
            {currentUserOption?.label !== "Dione" && (
              <button
                onClick={() =>
                  handleDownloadChart(
                    indiceMensalChartRef,
                    "Gráfico de Índices de Metas/Vendas Individual"
                  )
                }
              >
                Baixar Gráfico
              </button>
            )}
          </div>
        </div>
      )}
      {activeChart === "serviceChart" && (
        <div className="chart-container">
          <h3>Gráfico de Sessões por Serviço</h3>
          <Bar
            ref={serviceChartRef}
            data={serviceChartData}
            options={serviceChartOptions}
          />
          {currentUserOption?.label !== "Dione" && (
            <button
              onClick={() =>
                handleDownloadChart(
                  serviceChartRef,
                  "Gráfico de Sessões por Serviço"
                )
              }
            >
              Baixar Gráfico
            </button>
          )}
        </div>
      )}

      {/* Filtros */}
      <h3>Filtros</h3>
      <div className="filters">
        <div className="select-container">
          <label>Nome Cliente:</label>
          <input
            type="text"
            name="nome"
            placeholder="Nome"
            value={filters.nome}
            onChange={handleFilterChange}
          />
        </div>
        <div className="select-container">
          <label>Data de Início:</label>
          <input
            type="date"
            name="dataInicio"
            placeholder="Data Início"
            value={filters.dataInicio}
            onChange={handleFilterChange}
          />
        </div>
        <div className="select-container">
          <label>Data de Fim:</label>
          <input
            type="date"
            name="dataFim"
            placeholder="Data Fim"
            value={filters.dataFim}
            onChange={handleFilterChange}
          />
        </div>
        <div className="select-container">
          <label>Contrato Digital:</label>
          <select
            name="contratoDigital"
            value={filters.contratoDigital}
            onChange={handleFilterChange}
          >
            <option value="">Selecione</option>
            <option value="Sim">Sim</option>
            <option value="Pendente">Pendente</option>
          </select>
        </div>
        <div className="select-container">
          {" "}
          <label>Termo Digital Ômer:</label>
          <select
            name="termoDigitalOmer"
            value={filters.termoDigitalOmer}
            onChange={handleFilterChange}
          >
            <option value="">Selecione</option>
            <option value="Sim">Sim</option>
            <option value="Pendente">Pendente</option>
          </select>
        </div>

        <div className="select-container">
          {" "}
          <label>Termo Digital Depilação:</label>
          <select
            name="termoDigitalDepilacao"
            value={filters.termoDigitalDepilacao}
            onChange={handleFilterChange}
          >
            <option value="">Selecione</option>
            <option value="Sim">Sim</option>
            <option value="Pendente">Pendente</option>
          </select>
        </div>
        <div className="select-container">
          {" "}
          <label>Vendedor:</label>
          <select
            name="vendedor"
            value={filters.vendedor}
            onChange={handleFilterChange}
          >
            <option value="">Selecione</option>
            <option value="Ana Claudia">Ana Claudia</option>
            <option value="Camila">Camila</option>
            <option value="Carolina">Carolina</option>
            <option value="Clara">Clara</option>
            <option value="Enny">Enny</option>
            <option value="Isabella">Isabella</option>
            <option value="Rayssa">Rayssa</option>
            <option value="Thaís">Thaís</option>
          </select>
        </div>

        <button onClick={applyFilters}>Aplicar Filtros</button>
        <button onClick={resetFilters}>Limpar Filtros</button>
      </div>
      {/* Tabela */}
      <div className="table-wrapper">
        <table border="1">
          <thead>
            <tr>
              <th>Nome</th>
              <th>CPF</th>
              <th>Data</th>
              <th>Tipo Procedimento</th>
              <th>Valor</th>
              <th>Desconto Total</th>
              <th>Valor Cheio</th>
              <th>Vendedor</th>
              <th>Origem</th>
              <th>Entrada</th>
              <th>Forma Pgto Entrada</th>
              <th>Saldo</th>
              <th>Forma Pgto Saldo</th>
              <th>Parcelas</th>
              <th>Serviço</th>
              <th>Contrato Digital</th>
              <th>Termo Digital Ômer</th>
              <th>Termo Digital Depilação</th>
              {currentUserOption?.label === "Felipe" && <th>Edição</th>}
            </tr>
          </thead>
          <tbody>
            {filteredSales.map((sale) => (
              <tr key={sale.id} onDoubleClick={() => handleEditStart(sale.id)}>
                <td>
                  {editingRow === sale.id ? (
                    <input
                      type="text"
                      value={editedData.nome}
                      onChange={(e) => handleEditChange(e, "nome")}
                    />
                  ) : (
                    sale.nome
                  )}
                </td>
                <td>
                  {editingRow === sale.id ? (
                    <input
                      type="text"
                      value={editedData.cpf}
                      onChange={(e) => handleEditChange(e, "cpf")}
                    />
                  ) : (
                    sale.cpf
                  )}
                </td>
                <td>{sale.data}</td>
                <td>{sale.procedimento}</td>
                <td>
                  {editingRow === sale.id ? (
                    <input
                      type="number"
                      value={editedData.valor}
                      onChange={(e) => handleEditChange(e, "valor")}
                    />
                  ) : (
                    sale.valor
                  )}
                </td>
                <td>{sale.descontoTotal}</td>
                <td>{sale.valorCheio}</td>
                <td>
                  {editingRow === sale.id ? (
                    <input
                      type="text"
                      value={editedData.vendedor}
                      onChange={(e) => handleEditChange(e, "vendedor")}
                    />
                  ) : (
                    sale.vendedor
                  )}
                </td>
                <td>
                  {editingRow === sale.id ? (
                    <input
                      type="text"
                      value={editedData.origem}
                      onChange={(e) => handleEditChange(e, "origem")}
                    />
                  ) : (
                    sale.origem
                  )}
                </td>
                <td>{sale.entrada}</td>
                <td>{sale.formaPgtEntrada}</td>
                <td>{sale.saldo}</td>
                <td>{sale.formaPgtSaldo}</td>
                <td>{sale.parcelas}</td>
                <td>{sale.servico1}</td>
                <td>
                  {editingRow === sale.id ? (
                    <input
                      type="text"
                      value={editedData.contratoDigital}
                      onChange={(e) => handleEditChange(e, "contratoDigital")}
                    />
                  ) : (
                    sale.contratoDigital
                  )}
                </td>
                <td>
                  {editingRow === sale.id ? (
                    <input
                      type="text"
                      value={editedData.termoDigitalOmer}
                      onChange={(e) => handleEditChange(e, "termoDigitalOmer")}
                    />
                  ) : (
                    sale.termoDigitalOmer
                  )}
                </td>
                <td>
                  {editingRow === sale.id ? (
                    <input
                      type="text"
                      value={editedData.termoDigitalDepilacao}
                      onChange={(e) =>
                        handleEditChange(e, "termoDigitalDepilacao")
                      }
                    />
                  ) : (
                    sale.termoDigitalDepilacao
                  )}
                </td>
                <td>
                  {editingRow === sale.id ? (
                    <button onClick={() => handleEditSave(sale.id)}>
                      Salvar
                    </button>
                  ) : null}
                  {editingRow === sale.id &&
                  currentUserOption?.label === "Felipe" ? (
                    <button onClick={handleEditCancel}>Cancelar</button>
                  ) : null}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </section>
  );
}

export default ConsultaVenda;
